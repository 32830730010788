import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import firebase from '../../constants/firebase';
import * as Constants from '../../constants/global';
import close from '../../assets/icon/close_grey.svg';
import axios from 'axios';
import Progress from '../util/Progress';

import error from '../../assets/icon/error.svg';


const DetallePedido = (props) => {

    const [detalle, setDetalle] = useState();
    const [pedido, setPedido] = useState();
    const [loader, setLoader] = useState(false);
    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [ico, setIco] = useState(0);
    const [estado, setEstado] = useState(1);
    const [convenio, setConvenio] = useState()
    const [empleado, setEmpleado] = useState()

    const handleClose = () => {
        props.callback(true)
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
        props.updatePedidos();
    }

    const getIva = (lista) => {
        let iva = 0;
        lista.map((item, i) => {
            console.log(item);
            let subtotal = item.cantidad * item.iva;
            iva += (subtotal);
        })
        setIva(iva);
        console.log(iva);
    }

    const getIco = (lista) => {
        let ico = 0;
        lista.map((item, i) => {
            let subtotal = item.cantidad * item.ico;
            ico += (subtotal);
        })
        setIco(ico);
    }

    const getTotal = (lista) => {
        let total = 0;
        lista.map((item, i) => {
            total += item.cantidad * item.precio;
        })
        setTotal(total);
    }

    const getDetalle = () => {
        setLoader(true)
        console.log(props.pedido);
        let url = Constants.URL_BASE + Constants.WSDETALLEPEDIDO + props.pedido.id
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            console.log(props.pedido);
            setDetalle(response.data)
            setTimeout(() => {
                setLoader(false)
                setEmpleado(props.pedido.empleado)
                setConvenio(props.pedido.convenio)
                getTotal(response.data);
                getIco(response.data);
                getIva(response.data);
            }, 2000)
        }).catch((err) => {

        })
    }

    const handleCancelar = () => {
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSCANCELARPEDIDO;
        axios.post(url, {
            "numeroDoc": pedido.numeroDoc
        },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then((response) => {
                //firebase.analytics().logEvent("checkout", props.usuario.doc + "-" + props.usuario.email);
                setLoader(false);
                setEstado(4);
            }).catch((err) => {
                setLoader(false);

            })
    }

    useEffect(() => {
        console.log(props.usuario);
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
        setPedido(props.pedido);
        setEstado(props.pedido.estadoId);
        getDetalle();
    }, [])


    return (
        <div className="modal modal-sucursal">
            <div className="modal-content table-content">
                <div className="title">
                    <p>Número de orden: {pedido ? (pedido.numeroDoc) : (null)}</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img
                            src={close}
                            width="15"
                            height="15"
                            alt="icon close" />
                    </span>
                </div>
                <div className="content">
                    <div className="list-sucursales">
                        {detalle ? (
                            <React.Fragment>
                                {loader ? <Progress color={"#0194C5"}></Progress> : (
                                    <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="mytabla">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Nombre</th>
                                                        <th className="numero">Cantidad</th>
                                                        <th className="numero">Iva</th>
                                                        <th className="numero">Precio</th>
                                                        <th className="numero">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-hover">
                                                    {detalle.map((item, i) => (
                                                        <tr key={i}>
                                                            <td className="text-left">{item.nombre}</td>
                                                            <td className="numero">{item.cantidad}</td>
                                                            {props.pedido.acceso == 1 ? (
                                                                <td className="numero">
                                                                    {" $" + new Intl.NumberFormat("en", {
                                                                        numberingSystem: "latn",
                                                                        style: "decimal",
                                                                        currency: "COP"
                                                                    }).format(((item.iva - (item.iva * props.usuario.descuento)/100) * item.cantidad).toFixed(0))}
                                                                </td>
                                                            ) : ( 
                                                                <td className="numero">
                                                                    {" $" + new Intl.NumberFormat("en", {
                                                                        numberingSystem: "latn",
                                                                        style: "decimal",
                                                                        currency: "COP"
                                                                    }).format(Math.round(item.iva * item.cantidad))}
                                                                </td>
                                                            )}
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    currency: "COP"
                                                                }).format(item.precio)}</td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    currency: "COP"
                                                                }).format((item.cantidad * item.precio))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="4" className="numero"><b>Subtotal:</b></td>
                                                        <td className="numero">
                                                            {" $" + new Intl.NumberFormat("en", {
                                                                numberingSystem: "latn",
                                                                style: "decimal",
                                                                currency: "COP"
                                                            }).format(Math.round(total - (iva - ico)))}
                                                        </td>
                                                    </tr>
                                                    {props.pedido.acceso == 1 ? (
                                                        <tr>
                                                            <td colSpan="4" className="numero"><b>Iva:</b></td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    currency: "COP"
                                                                }).format(props.pedido.ivaAplicado)}
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" className="numero"><b>Iva:</b></td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    currency: "COP"
                                                                }).format(Math.round(iva))}
                                                            </td>
                                                        </tr>
                                                    )}



                                                    <tr>
                                                        <td colSpan="4" className="numero"><b>Envío:</b></td>
                                                        <td className="numero">
                                                            Gratis
                                                        </td>
                                                    </tr>
                                                    {empleado == 1 ? (
                                                        <>
                                                            <tr>
                                                                <td colSpan="4" className="numero"><b>Descuento:</b></td>
                                                                <td className="numero">
                                                                    {" $" +
                                                                        new Intl.NumberFormat("en", {
                                                                            numberingSystem: "latn",
                                                                            style: "decimal",
                                                                            currency: "COP",
                                                                        }).format((((total - iva - ico) * props.pedido.descuentoAplicado) / 100).toFixed(0))}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="4" className="numero"><b>Total:</b></td>
                                                                <td className="numero">
                                                                    {" $" + new Intl.NumberFormat("en", {
                                                                        numberingSystem: "latn",
                                                                        style: "decimal",
                                                                        currency: "COP"
                                                                    }).format(Math.round((total - iva - ico) - (((total - iva - ico) * props.pedido.descuentoAplicado) / 100) + (((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100))}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4" className="numero"><b>Total:</b></td>
                                                            <td className="numero">
                                                                {" $" + new Intl.NumberFormat("en", {
                                                                    numberingSystem: "latn",
                                                                    style: "decimal",
                                                                    currency: "COP"
                                                                }).format(total)}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="panel-button">
                                            <button className="btn-aceptar" onClick={() => handleClose()}>Cerrar</button>
                                            {estado == 1 && props.pedido.tipoPago == "CT" ? (
                                                <button className="btn-aceptar" onClick={() => handleCancelar()}>Cancelar pedido</button>
                                            ) : null}
                                            {estado == 4 ? (
                                                <h3 style={{ color: "red" }}>Este pedido ha sido cancelado.</h3>
                                            ) : (estado == 5 ?
                                                <h3 style={{ color: "red" }}>Este pedido ha sido rechazado.</h3>
                                                : (estado == 8 ?
                                                    <h3 style={{ color: "red" }}>Este pedido ha sido cancelado, pago no realizado.</h3>
                                                    : null))}
                                        </div>

                                    </React.Fragment>
                                )}

                            </React.Fragment>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetallePedido);