import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import firebase from "../../constants/firebase";
import { withRouter } from "react-router-dom";
import Progress from "../util/Progress";
import Navbar from "../navbar/Navbar";
import Sub_nav from "../navbar/Sub_nav";
import AlertModal from "../modal/AlertModal";
import CheckOut from "../checkout/CheckOut";
import Footer from "../footer/Footer";
import Search from "../util/Search";

import * as Constants from "../../constants/global";
import Auth from "../../helpers/Auth";
import { RadioGroup, RadioButton } from "react-radio-buttons";

// REDUX
import ActualizarUsuario from "../../store/usuario/action";
import ActualizarCarrito from "../../store/carrito/action";
import ActualizarEmpleado from '../../store/empleado/action'
import { selectActiveCarrito } from "../../store/carrito/reducer";
import { selectActiveUser } from "../../store/usuario/reducer";

import minus from "../../assets/icon/minus.svg";
import plus from "../../assets/icon/plus.svg";
import error from "../../assets/icon/error.svg";
import noimage from "../../assets/img/logo_III.png";
import eliminar from "../../assets/icon/eliminar.svg";
import { FcHighPriority } from "react-icons/fc";
import ThankYouPage from '../pedido/ThankYouPage';

// import TagManager from 'react-gtm-module'
import sha256 from "crypto-js/sha256";

var uniqid = require('uniqid')

const Carrito = (props) => {
  const style = {
    "text-align": "center",
    "padding-top": "130px",
    "font-size": "1.5rem",
  };

  const [search, setSearch] = useState("");
  const [alerta, setAlerta] = useState();
  const [total, setTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [ico, setIco] = useState(0);
  const [checkOut, setCheckOut] = useState(false);
  const [loader, setLoader] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [message, setMessage] = useState();
  const [topPedido, setTopePedido] = useState();
  const [cravings, setCravings] = useState([]);
  const [pay, setPay] = useState(true);
  const [dataAvvillas, setDataAvvillas] = useState();
  const [listProducts, setListProducts] = useState();
  const [metodoOnline, setMetodoOnline] = useState()
  const [disableBotonPago, setDisableBotonPago] = useState(false);
  const [totalNomina, setTotalNomina] = useState(0)
  const [cupoDisponibleActual, setCupoDisponibleActual] = useState(0)

  const handleSearch = (value) => {
    props.history.push("/catalogo/packs-15?q=" + value);
  };

  const getIva = () => {
    let listCarrito = Auth.getCarrito();
    let iva = 0;
    Object.keys(listCarrito).map((item, i) => {
      console.log(listCarrito[item])
      let subtotal = listCarrito[item]["cantidad"] * listCarrito[item]["iva"];
      iva += subtotal;
    });
    setIva(iva);
  };

  const getIco = () => {
    let listCarrito = Auth.getCarrito();
    let ico = 0;
    Object.keys(listCarrito).map((item, i) => {
      let subtotal = listCarrito[item]["cantidad"] * listCarrito[item]["ico"];
      ico += subtotal;
    });
    setIco(ico);
  };

  const getTotal = () => {
    let listCarrito = Auth.getCarrito();
    let total = 0;
    Object.keys(listCarrito).map((item, i) => {
      total += listCarrito[item]["cantidad"] * listCarrito[item]["precio"];
    });
    setTotal(total);
  };

  // productos
  const handleMenosProducto = (item) => {
    var carrito = props.carrito || {};
    if (carrito[item.codigoSku]) {
      if (carrito[item.codigoSku]["cantidad"] == 1) {
        delete carrito[item.codigoSku];
      } else {
        carrito[item.codigoSku]["cantidad"] -= 1;
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
  };
  const handleChangeCantidadProducto = (item, valor) => {

    var carrito = props.carrito || {};
    if (valor <= 0) {
      delete carrito[item.codigoSku];
    } else {
      if (carrito[item.codigoSku]) {
        if (true) {
          if (parseInt(valor) <= carrito[item.codigoSku]["inventario"]) {
            carrito[item.codigoSku]["cantidad"] = parseInt(valor);
          } else {
            setAlerta({
              tipo: "info",
              titulo: "Sin Inventario.",
              mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + carrito[item.codigoSku]["inventario"] + " unidades",
              btnOk: "Aceptar",
              fnOk: function () { },
              fnCancel: null,
            });
          }
        } else {
          setAlerta({
            tipo: "error",
            titulo: "Error.",
            mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
            btnOk: "Aceptar",
            fnOk: function () { },
            fnCancel: null,
          });
        }
      } else {
        carrito[item.codigoSku] = {
          id: item.id,
          nombre: item.nombre,
          precio: item.precio,
          cantidad: parseInt(valor),
          iva: item.iva,
          ico: parseInt(item.ico),
          inventario: item.inventario,
          categoria: item.categoria,
          codigoSku: item.codigoSku
        };
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
  };
  const handleMasProducto = (item) => {

    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) {
      /* firebase
        .analytics()
        .logEvent("agregar_carrito", item.codigoSku + "-" + item.nombre); */
      var carrito = props.carrito || {};
      if (carrito[item.codigoSku]) {
        if (carrito[item.codigoSku]["cantidad"]) {
          if (carrito[item.codigoSku]["cantidad"] < item.inventario) {
            carrito[item.codigoSku]["cantidad"] += 1;
          } else {
            setAlerta({
              tipo: "info",
              titulo: "Sin Inventario.",
              mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + item.inventario + " unidades",
              btnOk: "Aceptar",
              fnOk: function () { },
              fnCancel: null,
            });
          }
        } else {
          setAlerta({
            tipo: "error",
            titulo: "Error.",
            mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
            btnOk: "Aceptar",
            fnOk: function () { },
            fnCancel: null,
          });
        }
      } else {
        carrito[item.codigoSku] = {
          id: item.id,
          nombre: item.nombre,
          precio: parseFloat(item.precio),
          iva: item.iva,
          ico: parseInt(item.ico),
          cantidad: 1,
          inventario: item.inventario,
          categoria: item.categoria,
          codigoSku: item.codigoSku,
        };
      }
      Auth.setCarrito(carrito);
      props.ActualizarCarrito(Auth.getCarrito());
    } else {
      props.ActualizarCarrito({});
    }
  };

  // AUMENTA LA CANTTIDAD
  const handleMas = (codigo, item) => {
    setCheckOut(false);
    var carrito = props.carrito || {};
    if (carrito[codigo]) {
      if (parseInt(carrito[codigo]["cantidad"])) {
        if (parseInt(carrito[codigo]["cantidad"]) < item.inventario) {
          carrito[codigo]["cantidad"] += 1;
        } else {
          setAlerta({
            tipo: "info",
            titulo: "Sin Inventario.",
            mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + item.inventario + " unidades",
            btnOk: "Aceptar",
            fnOk: function () { },
            fnCancel: null,
          });
        }
      } else {
        setAlerta({
          tipo: "error",
          titulo: "Error.",
          mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
          btnOk: "Aceptar",
          fnOk: function () { },
          fnCancel: null,
        });
      }
    } else {
      carrito[codigo] = {
        nombre: item.nombre,
        precio: item.precio,
        cantidad: 1,
        iva: item.iva,
        ico: parseInt(item.ico),
        inventario: item.inventario,
        categoria: item.categoria,
        codigoSku: item.codigoSku,
        urlImagen : item.imagen == null || item.imagen.length < 2 ? item.codigoSku : item.imagen
      };
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());

    // const tagManagerArgs = {
    //   gtmId: process.env.REACT_APP_KEY_GOOGLE_TAG
    // }

    // TagManager.initialize(tagManagerArgs);
    // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'addToCart',
    //     ecommerce: [
    //       {
    //         currencyCode: 'COP',
    //         add: {
    //           products: [
    //             {
    //               'name': item.nombre,
    //               'id': item.codigoSku,
    //               'price': item.precio,
    //               'category': item.categoria,
    //               'quantity': 1,
    //             }
    //           ]
    //         }
    //       }
    //     ]
    //   }
    // })

    getIva();
    getIco();
    getTotal();
  };

  const handleMenos = (codigoSku, item) => {
    if (item.warning != undefined) {
      props.carrito[codigoSku]["warning"] = false
    }
    setCheckOut(false);
    var carrito = props.carrito || {};

    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'removeFromCart',
    //     ecommerce: {
    //       remove: {
    //         products: [{
    //           'name': carrito[codigoSku]["nombre"],
    //           'id': carrito[codigoSku]["id"],
    //           'price': carrito[codigoSku]["precio"],
    //           'category': carrito[codigoSku]["categoria"],
    //           'quantity': 1
    //         }]
    //       }
    //     }
    //   }
    // })

    if (carrito[codigoSku]) {
      if (carrito[codigoSku]["cantidad"] == 1) {
        delete carrito[codigoSku];
      } else {
        carrito[codigoSku]["cantidad"] -= 1;
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
    getIva();
    getIco();
    getTotal();
  };

  const handleChangeCantidad = (codigoSku, item, valor) => {
    setCheckOut(false);
    var carrito = props.carrito || {};
    if (valor <= 0) {
      delete carrito[codigoSku];
    } else {
      if (carrito[codigoSku]) {
        if (parseInt(valor)) {
          if (parseInt(valor) <= item.inventario) {
            carrito[codigoSku]["cantidad"] = parseInt(valor);
          } else {
            setAlerta({
              tipo: "info",
              titulo: "Sin Inventario.",
              mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + item.inventario + " unidades",
              btnOk: "Aceptar",
              fnOk: function () { },
              fnCancel: null,
            });
          }
        } else {
          carrito[codigoSku]["cantidad"] = carrito[codigoSku]["cantidad"];
          setAlerta({
            tipo: "error",
            titulo: "Error.",
            mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
            btnOk: "Aceptar",
            fnOk: function () { },
            fnCancel: null,
          });
        }
      } else {
        carrito[codigoSku] = {
          nombre: item.nombre,
          precio: item.precio,
          cantidad: parseInt(valor),
          iva: item.iva,
          ico: parseInt(item.ico),
          inventario: item.inventario,
          categoria: item.categoria,
          codigoSku: item.codigoSku,
          urlImagen : item.imagen == null || item.imagen.length < 2 ? item.codigoSku : item.imagen
        };
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
    getIva();
    getIco();
    getTotal();
  };

  const handleEliminar = (item) => {
    console.log(item);
    setCheckOut(false);
    var carrito = props.carrito || {};
    if (carrito[item].cantidad > 0) {
      setAlerta({
        tipo: "error",
        icon: Constants.URL_BASE + "image/" + (item || "") + ".png",
        titulo: "Eliminar producto.",
        mensaje: "¿Está seguro de eliminar " + carrito[item].nombre + " ?",
        btnOk: "Si, eliminar",
        btnCancel: "No, cancelar",
        fnOk: function () {
          carrito[item].cantidad = 0;
          // handleMenos(item);
          delete carrito[item];
          setTimeout(() => {
            Auth.setCarrito(carrito);
            props.ActualizarCarrito(Auth.getCarrito());
            getIva();
            getIco();
            getTotal();
          }, 100);
        },
        fnCancel: function () { },
      });
    }
  };

  const handleCancelarPedido = () => {
    setCheckOut(false);
    setAlerta({
      tipo: "error",
      titulo: "Cancelar pedido.",
      mensaje:
        "¿Esta seguro de cancelar el pedido?. Se perderan todos los productos.",
      btnOk: "Si, eliminar",
      btnCancel: "No, cancelar",
      fnOk: function () {
        Auth.removeCarrito();
        props.ActualizarCarrito({});
        getIva();
        getIco();
        getTotal();
      },
      fnCancel: function () { },
    });
  };

  const handelCheckOut = () => {
    setDisableBotonPago(false)
    let cliente = props.usuario;
    var carrito = Auth.getCarrito();
    var listProducts = []
    Object.keys(carrito).map((item, i) => {
      listProducts.push({
        name: carrito[item].nombre,
        id: carrito[item].id,
        price: carrito[item].precio,
        category: 'Papas',
        quantity: carrito[item].cantidad
      });
    });
    if (cliente) {
      // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'checkout',
      //     ecommerce:
      //     {
      //       checkout: {
      //         actionField: {
      //           step: 2
      //         },
      //         products: listProducts ? listProducts : {}
      //       }
      //     }
      //   }
      // })
      setCheckOut(true);
      /* firebase
        .analytics()
        .logEvent("checkout", cliente.doc + "-" + cliente.email); */
    } else {
      setAlerta({
        tipo: "info",
        titulo: "Inicio de sesión.",
        mensaje: "Por favor inicie sesión o registrese para terminar el pedido",
        btnOk: "Ingresar o Registrarse",
        btnCancel: "Cancelar",
        fnOk: function () {
          props.history.push("/login");
        },
        fnCancel: function () { },
      });
    }
  };

  const handlevalidateInvetori = () => {
    let result = []
    let pedido = props.carrito
    axios.get(Constants.URL_BASE + Constants.WSINVENTARIOEXTERNO).then((response) => {
      result = response.data
    }).catch((err) => {
      console.log(err);
    })
    setTimeout(() => {
      let validacionExitosa = 1
      if (result.length > 0) {
        //iteramos por cada elemento del pedido realizado
        for (const item in pedido) {
          // iteramos cada codigo de producto por la lista obtenia de la bd 
          result.map((data) => {
            // si hay coincidencia entre el pedido y la bd validamos el inventario
            if (item == data.producto) {
              let inventarioRevisado = pedido[item].inventario - data.cantidad
              let cantidadPedida = pedido[item].cantidad
              let NombreProducto = pedido[item].nombre
              let crearWarning = props.carrito[item]
              if (inventarioRevisado < cantidadPedida) {
                validacionExitosa = 0
                crearWarning["warning"] = true
                setAlerta({
                  tipo: "info",
                  titulo: "Sin Inventario.",
                  mensaje: "Lo sentimos, la cantidad disponible para el producto " + NombreProducto + " es de " + inventarioRevisado + " unidades",
                  btnOk: "Aceptar",
                  fnOk: function () { },
                  fnCancel: null,
                });
              }
            }
          })
        }
      }
      if (validacionExitosa == 1) {
        if (metodoOnline == "NOMINA") {
          setAlerta({
            tipo: "info",
            // titulo: "Inicio de sesión.",
            mensaje: "*Autorizo expresamente para que, de mi salario, liquidación final de prestaciones sociales y demás emolumentos a que tenga derecho me sea descontada la suma correspondiente al pedido que por este medio electrónico realice; Igualmente autorizo a COMESTIBLES RICOS S.A., a descontar de mi liquidación final de salario, bonificaciones, prestaciones sociales, vacaciones, indemnizaciones, liquidación final de prestaciones sociales o de cualquier suma de dinero que se genere a mi favor, las sumas de dinero que esté adeudando por estos conceptos si se llegare a terminar mi contrato de trabajo por cualquier causa.  En caso de que mi contrato de trabajo finalice por cualquier motivo y que el monto de mi liquidación no alcance a cubrir la totalidad del dinero adeudado a la compañía con ocasión de los hechos indicados en este documento, me obligo a efectuar el pago correspondiente a los dineros adeudados en los 60 días siguientes de la finalización del contrato.",
            btnOk: "Aceptar",
            btnCancel: "Cancelar",
            fnOk: function () {
              handlePedido()
            },
            fnCancel: function () {
              setDisableBotonPago(false)
            },
          });
        } else {
          handlePedido()
        }
      } else {
        console.log("pedido rechazado");
      }
    }, 1500);

  }

  const handlePedido = () => {
    var totalGastadoNomina = 0
    if (props.usuario.accesoEmpleado == 1) {
      let cupoDisponible = props.usuario.cupo - totalGastadoNomina
      if (metodoOnline == "NOMINA" && props.usuario.cupo < total - (((total - iva - ico) * props.usuario.descuento) / 100).toFixed(0)) {
        setCheckOut(false)
        setDisableBotonPago(false)
        return setAlerta({
          tipo: "info",
          titulo: "Cupo no disponible.",
          mensaje: "Recuerda que tu cupo máximo permitido para esta compra es de " + "$" + new Intl.NumberFormat("en", { currency: "COP" }).format(cupoDisponible),
          btnOk: "Aceptar",
          fnOk: function () { },
          fnCancel: null,
        });
      } else {
        axios.get(Constants.URL_BASE + Constants.WSPEDIDOSDIANOMINA + "/" + props.usuario.doc)
          .then((res) => {
            res.data.map((item) => {
              totalGastadoNomina = totalGastadoNomina + item.total
            })
          })
          .catch((err) => {
            console.log(err);
          });
        setTimeout(() => {
          let cupoDisponible = props.usuario.cupo - totalGastadoNomina
          if (metodoOnline == "NOMINA" && cupoDisponible <= total - (((total - iva - ico) * props.usuario.descuento) / 100).toFixed(0)) {
            setCheckOut(false)
            return setAlerta({
              tipo: "warning",
              titulo: "Cupo no disponible.",
              mensaje: cupoDisponible > 0 ? "Recuerda que tu cupo máximo permitido para esta compra es de " + new Intl.NumberFormat("en", { currency: "COP" }).format(cupoDisponible) + " $ para este metodo de pago" : "No tienes cupo disponible para este tipo de pago",
              btnOk: "Aceptar",
              fnOk: function () { },
              fnCancel: null,
            });
          } else {
            setLoader(true);
            let cliente = props.usuario;
            let detalle = [];
            Object.keys(props.carrito).map((item, i) => {
              detalle.push({
                codigoSku: item,
                cantidad: props.carrito[item].cantidad,
                idProducto : props.carrito[item].id
              });
            });
            let pedido = {
              observacion: cliente.observacion ? cliente.observacion : "",
              tipoPago: pay ? "Pago en linea" : "CT",
              clienteId: cliente.id,
              plataforma: "WEB",
              fecha: cliente.fecha,
              detalle: detalle,
              direccionId: cliente.direccionId,
              transaccionconvenioId: uniqid(),
              total: total ? Math.round((total - iva - ico) - (((total - iva - ico) * props.usuario.descuento) / 100) + (((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100) : total,
              convenio: metodoOnline == undefined ? "EFECTIVO" : metodoOnline,
              empleado: props.usuario.accesoEmpleado = 1 ? 1 : 0,
              descuento: props.usuario.descuento ? props.usuario.descuento : 0,
              ivaAplicado : Math.round((((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100)
            };
            var carrito = Auth.getCarrito();
            var listProducts = []
            Object.keys(carrito).map((item, i) => {
              listProducts.push({
                name: carrito[item].nombre,
                id: carrito[item].id,
                price: carrito[item].precio,
                category: 'Papas',
                quantity: carrito[item].cantidad
              });
            });

            if (cliente) {
              // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
              // TagManager.dataLayer({
              //   dataLayer: {
              //     event: 'checkout',
              //     ecommerce: {
              //       checkout: {
              //         actionField: {
              //           step: 3
              //         },
              //         products: listProducts ? listProducts : {}
              //       }
              //     }
              //   }
              // })
              axios
                .post(Constants.URL_BASE + Constants.WSPEDIDO, pedido, {
                  headers: {
                    Authorization: `Bearer ${Auth.getToken()}`,
                  },
                })
                .then((response) => {
                  setDataAvvillas({
                    referencia: response.data.orden,
                    observacion: props.usuario.observacion,
                    totalPay: props.usuario.accesoEmpleado == 1 ? Math.round((total - iva - ico) - (((total - iva - ico) * props.usuario.descuento) / 100) + (((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100) : total,
                    usuario: props.usuario,
                    transaccionConvenioId: pedido.transaccionconvenioId,
                    typePay: pay,
                  })
                  setEnviado(true);
                  setLoader(false);
                  setTotal(0);
                  setIco(0);
                  setIva(0);
                  setMessage(response.data.message);
                  setCheckOut(false);
                })
                .catch((err) => {
                  setLoader(false);
                });
            }
          }
        }, 1000);
      }
    } else {
      setLoader(true);
      let cliente = props.usuario;
      let detalle = [];
      Object.keys(props.carrito).map((item, i) => {
        detalle.push({
          codigoSku: item,
          cantidad: props.carrito[item].cantidad,
          idProducto : props.carrito[item].id
        });
      });
      
      let pedido = {
        observacion: cliente.observacion ? cliente.observacion : "",
        tipoPago: pay ? "Pago en linea" : "CT",
        clienteId: cliente.id,
        plataforma: "WEB",
        fecha: cliente.fecha,
        detalle: detalle,
        direccionId: cliente.direccionId,
        transaccionconvenioId: uniqid(),
        total: total ? total : 0,
        convenio: metodoOnline == undefined ? "EFECTIVO" : metodoOnline,
        empleado: 0,
        descuento: 0,
        ivaAplicado : Math.round(iva)
      };

      var carrito = Auth.getCarrito();
      var listProducts = []
      Object.keys(carrito).map((item, i) => {
        listProducts.push({
          name: carrito[item].nombre,
          id: carrito[item].id,
          price: carrito[item].precio,
          category: 'Papas',
          quantity: carrito[item].cantidad
        });
      });

      if (cliente) {
        // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: 'checkout',
        //     ecommerce: {
        //       checkout: {
        //         actionField: {
        //           step: 3
        //         },
        //         products: listProducts ? listProducts : {}
        //       }
        //     }
        //   }
        // })
       
        axios
          .post(Constants.URL_BASE + Constants.WSPEDIDO, pedido, {
            headers: {
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          })
          .then((response) => {
            setDataAvvillas({
              referencia: response.data.orden,
              observacion: props.usuario.observacion,
              totalPay: props.usuario.accesoEmpleado == 1 ? Math.round((total - iva - ico) - (((total - iva - ico) * props.usuario.descuento) / 100) + (((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100) : total,
              usuario: props.usuario,
              transaccionConvenioId: pedido.transaccionconvenioId,
              typePay: pay,
            })

            /* firebase
              .analytics()
              .logEvent("finalizar_checkout", cliente.doc + "-" + cliente.email); */
            setEnviado(true);
            setLoader(false);

            setTotal(0);
            setIco(0);
            setIva(0);
            setMessage(response.data.message);
            setCheckOut(false);
            //Auth.removeCarrito();
            //props.ActualizarCarrito({});

            //props.history.push("/thank-you-page/" + response.data.orden)
            // setAlerta({
            //   tipo: "success",
            //   titulo: "Pedido.",
            //   mensaje:
            //     response.data.message +
            //     (pay
            //       ? "no olvide realizar el pago para despachar la orden, concepto:"
            //       : "paga en efectivo cuando reciba."),
            //   btnOk: pay
            //     ? "Pagar: " +
            //       ("$" +
            //         new Intl.NumberFormat("en", {
            //           numberingSystem: "latn",
            //           style: "decimal",
            //           currency: "COP",
            //         }).format(total ? total : 0))
            //     : "OK",
            //   /* btnCancel: "Ir al catalogo", */
            //   fnOk: function () {
            //     pay ? setReferencia(response.data.orden) : props.history.push("/thank-you-page/" + response.data.orden) /* props.history.push("/catalogo") */;
            //   },
            //   /* fnCancel: function(){
            //       props.history.push('/')
            //       cliente.observacion=null
            //       props.ActualizarUsuario(cliente)
            //   } */
            // });
          })
          .catch((err) => {
            setLoader(false);
          });
      }
    }

  };

  const handleRedirect = (item) => {
    props.history.push(item);
  };

  const topePedido = () => {
    let url = Constants.URL_BASE + Constants.WSTOPEPEDIDO;
    axios.get(url).then((response) => {
      if (props.usuario) {
        if (props.usuario.accesoEmpleado == 1) {
          setTopePedido(response.data[0].valor);
        }
        else {
          setTopePedido(response.data[1].valor);
        }
      } else {
        setTopePedido(response.data[1].valor);
      }

    });
  };

  const validatePedido = () => {
    var ubicacion = Auth.getUbicacion();
    var carrito = Auth.getCarrito();

    if (Object.keys(ubicacion).length > 0) {
      let poligono;
      if (ubicacion.direccion)
        poligono = (JSON.parse(ubicacion.direccion))
      let url =
        Constants.URL_BASE +
        Constants.WSCATALOGO +
        "&DepartamentoId=" +
        ubicacion.departamentoId +
        "&MunicipioId=" +
        ubicacion.municipioId
      if (poligono) {
        url +=
          "&latitud=" +
          poligono.latitud +
          "&longitud=" +
          poligono.longitud
      }
      let detalle = [];
      Object.keys(carrito).map((item, i) => {
        detalle.push({
          codigoSku: item,
          precio: carrito[item].precio,
          iva: carrito[item].iva,
          ico: carrito[item].ico,
          inventario: item.inventario,
          categoria: item.categoria,
          codigoSku: item.codigoSku
        });
      });

      axios.post(url, { detalle: detalle }).then((response) => {
        var dataArray = response.data;
        var carritoAux = {};
        var listProducts = []
        dataArray.map((item, i) => {
          if (carrito[item.codigoSku]) {
            listProducts.push({
              'name': carrito[item.codigoSku]["nombre"],
              'id': item.codigoSku,
              'price': item.precio,
              'category': item.categoria,
              'quantity': carrito[item.codigoSku]["cantidad"]
            })
            carritoAux[item.codigoSku] = {
              nombre: carrito[item.codigoSku]["nombre"],
              precio: item.precio,
              cantidad: parseInt(carrito[item.codigoSku]["cantidad"]),
              iva: item.iva,
              ico: parseInt(item.ico),
              inventario: item.inventario,
              categoria: item.categoria,
              codigoSku: item.codigoSku
            };
          }
        });
        setListProducts(listProducts)
        // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: 'checkout',
        //     ecommerce: {
        //       checkout: {
        //         actionField: {
        //           step: 1
        //         },
        //         products: listProducts
        //       }
        //     }
        //   }
        // })

        Auth.setCarrito(carritoAux);
        props.ActualizarCarrito(Auth.getCarrito());

        getIva();
        getIco();
        getTotal();
      });
    }
  };

  const getProducts = () => {
    let url = Constants.URL_BASE + Constants.WSPRODUCTOS;
    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) {
      let poligono;
      if (ubicacion.direccion)
        poligono = (JSON.parse(ubicacion.direccion))
      url +=
        "?DepartamentoId=" +
        ubicacion.departamentoId +
        "&MunicipioId=" +
        ubicacion.municipioId
      if (poligono) {
        url +=
          "&latitud=" +
          poligono.latitud +
          "&longitud=" +
          poligono.longitud
      }
      if (props.usuario) {
        if (props.usuario.accesoEmpleado != 0) {
          url += "&empleado=" + props.usuario.accesoEmpleado + "&codigoBodega=" + props.usuario.bodega
          url += "&bodegaUser=" + props.usuario.bodega
        } else {
          url += "&bodegaUser=" + props.usuario.bodega
        }
      } else {
        url += "&bodegaUser=11060"
      }
    }

    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setLoader(false);
        let temp = [];
        temp[0] = res.data[0];
        temp[1] = res.data[1];
        temp[2] = res.data[2];
        setCravings(temp);
      })
      .catch((err) => {
        setLoader(false);
      });
    getIva();
    getIco();
    getTotal();
  };

  const getCupoDisponible = () => {
    var totalGastadoNomina = 0
    setTimeout(() => {
      axios.get(Constants.URL_BASE + Constants.WSPEDIDOSDIANOMINA + "/" + props.usuario.doc)
        .then((res) => {
          res.data.map((item) => {
            totalGastadoNomina = totalGastadoNomina + item.total
          })
        })
        .catch((err) => {
          console.log(err);
        });
      setTimeout(() => {
        let cupoDisponible = props.usuario.cupo - totalGastadoNomina
        setCupoDisponibleActual(cupoDisponible)
      }, 500);
    }, 500);


  }


  useEffect(() => {
    getCupoDisponible();
    validatePedido();
    topePedido();
    getProducts();
    let URLactual = (new URL(document.location)).searchParams;
    let referencePago = URLactual.get("referenceCode")
    let respuestaPago = URLactual.get("message")
    let valuePago = URLactual.get("TX_VALUE")
    let referenceTransaction = URLactual.get("reference_pol")
    if (respuestaPago) {
      setDataAvvillas({
        referencia: referencePago,
        observacion: respuestaPago,
        totalPay: valuePago,
        usuario: props.usuario,
        transaccionConvenioId: null,
        typePay: false,
        respuestaPagos: referenceTransaction,
        confirmationPage: true,
      })
    } else {
    }
  }, [props.usuario]);

  useEffect(() => {
    console.log(props.carrito);
    topePedido()
  })

  return (
    <React.Fragment>
      <div className="content-carrito">
        <div className="navbar principal navcarrito">
          <Navbar item={2} />
          {/* <Search search={search} updateSearch={handleSearch} /> */}
          <Sub_nav />
        </div>

        {
          dataAvvillas ? <ThankYouPage data={dataAvvillas}></ThankYouPage>
            : <div className="layout">
              <div className="mobile">
                <div className="pedido-list carrito">
                  {loader ? (
                    <Progress color={"#2098de"} />
                  ) : (
                    <React.Fragment>
                      {props.carrito && !enviado ? (
                        <React.Fragment>
                          {Object.keys(props.carrito).length > 0 ? (
                            <React.Fragment>
                              <h2 className="titulo-carrio">Verifica tu pedido</h2>
                              <h4 className="titulo-carrio">
                                Si todo está correcto haz clic en confirmar pedido.
                              </h4>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <h2 className="titulo-carrio">
                                El carrito está vacío
                              </h2>
                              <div style={{ textAlign: "center" }}>
                                <button
                                  className="btn-large btn-catalogo"
                                  onClick={(e) => handleRedirect("/")}
                                >
                                  Ir al catálogo
                                </button>
                              </div>
                            </React.Fragment>
                          )}

                          <div className="product-list">
                            {Object.keys(props.carrito).map((item, i) => (
                              <div className="item item-carrito" key={i}>
                                <img
                                  className="img-product"
                                  src={
                                    Constants.URL_BASE + "/image/" + props.carrito[item].urlImagen + ".png"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = noimage;
                                  }}
                                ></img>
                                <div className="start">
                                  <p className="nombre">
                                    {props.carrito[item].nombre}
                                  </p>
                                  {props.carrito[item].descuento > 0 && (
                                    <p className="line-through">
                                      {" $" +
                                        new Intl.NumberFormat("en", {
                                          numberingSystem: "latn",
                                          style: "decimal",
                                          currency: "COP",
                                        }).format(Math.round((props.carrito[item].precio * 100) / (100 - props.carrito[item].descuento)))}
                                    </p>
                                  )}
                                  <p className="precio">
                                    {" $" +
                                      new Intl.NumberFormat("en", {
                                        numberingSystem: "latn",
                                        style: "decimal",
                                        currency: "COP",
                                      }).format(props.carrito[item].precio)}
                                  </p>
                                  <p className="regular">
                                    Total
                                    {" $" +
                                      new Intl.NumberFormat("en", {
                                        numberingSystem: "latn",
                                        style: "decimal",
                                        currency: "COP",
                                      }).format(
                                        props.carrito[item].precio *
                                        props.carrito[item].cantidad
                                      )}
                                  </p>
                                </div>
                                <div className="end">
                                  <div className="action">
                                    {props.carrito[item].warning == true ? (
                                      <FcHighPriority
                                        color="red"
                                        size={30}
                                        style={{ marginRight: "10px" }}
                                      />
                                    ) : null}
                                    <div className="controls">
                                      <button
                                        role="button"
                                        onClick={() =>
                                          handleMenos(item, props.carrito[item])
                                        }
                                      >
                                        <img
                                          src={minus}
                                          alt="icon minus"
                                          width="15px"
                                          height="15px"
                                        ></img>
                                      </button>
                                      <input
                                        type="number"
                                        minLength="0"
                                        maxLength="99"
                                        onChange={(e) =>
                                          handleChangeCantidad(
                                            item,
                                            props.carrito[item],
                                            e.target.value
                                          )
                                        }
                                        value={props.carrito[item].cantidad}
                                      />
                                      <button
                                        role="button"
                                        onClick={(e) =>
                                          handleMas(item, props.carrito[item])
                                        }
                                      >
                                        <img
                                          src={plus}
                                          alt="icon plus"
                                          width="15px"
                                          height="15px"
                                        ></img>
                                      </button>
                                    </div>
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => handleEliminar(item)}
                                    >
                                      <img
                                        src={eliminar}
                                        alt="icon delete"
                                        width="30px"
                                        height="30px"
                                      ></img>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div style={style}>
                            <p>{message ? message : ""}</p>
                          </div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>

                <div className="total">
                  <div className="total-content">
                    <div className="item">
                      <p className="opc">Subtotal:</p>
                      <p className="val">
                        {" $" +
                          new Intl.NumberFormat("en", {
                            numberingSystem: "latn",
                            style: "decimal",
                            currency: "COP",
                          }).format(Math.round(total - iva - ico))}
                      </p>
                    </div>

                    {/* <div className="item">
                      <p className="opc">Ico:</p>
                      <p className="val">
                        {" $" +
                          new Intl.NumberFormat("en", {
                            numberingSystem: "latn",
                            style: "decimal",
                            currency: "COP",
                          }).format(ico)}
                      </p>
                    </div> */}

                    {props.usuario.accesoEmpleado == 1 && total > 1 ? (
                      <>
                        <div className="item">
                          <p className="opc">Descuento:</p>
                          <p className="val">{props.usuario.descuento} %</p>
                        </div>
                        <div className="item">
                          <p className="opc">Descuento aplicado:</p>
                          <p className="val">
                            {" $" +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(Math.round((((total - iva - ico) * props.usuario.descuento) / 100)))}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {props.usuario.accesoEmpleado == 1 ? (
                      <div className="item">
                        <p className="opc">Iva:</p>
                        <p className="val">
                          {" $" +
                            new Intl.NumberFormat("en", {
                              numberingSystem: "latn",
                              style: "decimal",
                              currency: "COP",
                            }).format(Math.round((((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100))}
                        </p>
                      </div>
                    ) : (
                      <div className="item">
                        <p className="opc">Iva:</p>
                        <p className="val">
                          {" $" +
                            new Intl.NumberFormat("en", {
                              numberingSystem: "latn",
                              style: "decimal",
                              currency: "COP",
                            }).format(Math.round(iva))}
                        </p>
                      </div>
                    )}
                    <div className="item">
                      <p className="opc">Total:</p>
                      <p className="val">

                        {props.usuario.accesoEmpleado == 1 ? (
                          <>
                            {
                              " $" +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(Math.round((total - iva - ico) - (((total - iva - ico) * props.usuario.descuento) / 100) + (((total - iva) - ((total - iva) * props.usuario.descuento / 100)) * ((iva * 100 / (total - iva)))) / 100))
                            }
                          </>
                        ) : (
                          <>
                            {" $" +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format((total))}
                          </>
                        )}

                      </p>
                    </div>
                    {total >= topPedido ?
                      (
                        <div className="item">
                          <p className="opc">Envío:</p>
                          <p className="val">Gratis</p>

                        </div>
                      ) : null}

                  </div>
                  {props.usuario.accesoEmpleado == 1 ? (
                    <p style={{ marginTop: "5px", color: "#877C75", marginLeft: "1%", fontSize: "0.95rem" }}>
                      <strong>
                        Cupo disponible para descuento por nómina : {" $ " +
                          new Intl.NumberFormat("en", {
                            numberingSystem: "latn",
                            style: "decimal",
                            currency: "COP",
                          }).format(cupoDisponibleActual)}
                      </strong>
                    </p>
                  ) : null}
                  <br />

                  <p className="titulo-carrio h-habiles">
                    El tiempo de entrega es de 24 horas hábiles
                  </p>

                  <React.Fragment>
                    {!loader && !message ? (
                      <React.Fragment>
                        {checkOut ? <CheckOut pay={pay} setPay={setPay} setMetodoOnline={setMetodoOnline} /> : null}
                        {props.usuario.accesoEmpleado == 1 ? (
                          <>
                            {!loader && total >= topPedido + ((topPedido * props.usuario.descuento) / 100) ? (
                              <React.Fragment>
                                {checkOut ? (

                                  <button
                                    disabled={disableBotonPago}
                                    id="confirmarPedido"
                                    type="submit"
                                    className="btn-large"
                                    onClick={() => (setDisableBotonPago(true), handlevalidateInvetori())}
                                  >
                                    Confirmar pedido
                                  </button>


                                ) : (
                                  <button
                                    type="submit"
                                    className="btn-large"
                                    onClick={(e) => handelCheckOut()}
                                  >
                                    Confirmar pedido
                                  </button>
                                )}
                              </React.Fragment>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {!loader && total >= topPedido ? (
                              <React.Fragment>
                                {checkOut ? (

                                  <button
                                    disabled={disableBotonPago}
                                    id="confirmarPedido"
                                    type="submit"
                                    className="btn-large"
                                    onClick={() => (setDisableBotonPago(true), handlevalidateInvetori())}
                                  >
                                    Confirmar pedido
                                  </button>


                                ) : (
                                  <button
                                    type="submit"
                                    className="btn-large"
                                    onClick={(e) => handelCheckOut()}
                                  >
                                    Confirmar pedido
                                  </button>
                                )}
                              </React.Fragment>
                            ) : null}
                          </>
                        )}


                        <button
                          type="button"
                          className="btn-large outline"
                          onClick={() => handleRedirect("/catalogo/gaseosas-7")}
                        >
                          Agregar más productos
                        </button>
                        {Object.keys(props.carrito).length > 0 ? (
                          <button
                            type="button"
                            className="btn-large outline"
                            onClick={(e) => handleCancelarPedido()}
                          >
                            Cancelar pedido
                          </button>
                        ) : null}
                        {props.usuario.accesoEmpleado == 1 ? (
                          <>
                            {!checkOut && !loader && total >= topPedido + ((topPedido * props.usuario.descuento) / 100) && (
                              <div className="cravings">
                                <div className="title">
                                  <h4>¿Un ultimo antojo?</h4>
                                </div>
                                {cravings.map((c) => (
                                  props.carrito[c.codigoSku] ? null : (
                                    <div className="whim">
                                      <div className="image">
                                        <img
                                          style={c.agotado == 1 ? { opacity: 0.3 } : {}}
                                          className="img-product"
                                          src={
                                            Constants.URL_BASE +
                                            "/image/" +
                                            c.codigoSku.trim() +
                                            ".png"
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = noimage;
                                          }}
                                        ></img>
                                      </div>
                                      <div className="actions">
                                        <div className="header">
                                          <p>{c.nombre}</p>
                                          <p>
                                            {" $" +
                                              new Intl.NumberFormat("en", {
                                                numberingSystem: "latn",
                                                style: "decimal",
                                                currency: "COP",
                                              }).format(c.precio)}
                                          </p>
                                        </div>
                                        {props.carrito[c.codigoSku] ? (
                                          <div className="controls">
                                            <button
                                              role="button"
                                              onClick={() => handleMenosProducto(c)}
                                            >
                                              <img
                                                src={minus}
                                                alt="icon minus"
                                                width="15px"
                                                height="15px"
                                              ></img>
                                            </button>
                                            <input
                                              type="number"
                                              minLength="0"
                                              maxLength="99"
                                              onChange={(e) =>
                                                handleChangeCantidadProducto(
                                                  c,
                                                  e.target.value
                                                )
                                              }
                                              value={props.carrito[c.codigoSku].cantidad}
                                            />
                                            <button
                                              role="button"
                                              onClick={() => handleMas(c)}
                                            >
                                              <img
                                                src={plus}
                                                alt="icon plus"
                                                width="15px"
                                                height="15px"
                                              ></img>
                                            </button>
                                          </div>
                                        ) : (
                                          <button
                                            className="add"
                                            style={{ backgroundColor: props.color }}
                                            onClick={() => handleMasProducto(c)}
                                          >
                                            Agregar
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {!checkOut && !loader && total >= topPedido && (
                              <div className="cravings">
                                <div className="title">
                                  <h4>¿Un ultimo antojo?</h4>
                                </div>
                                {cravings.map((c) => (
                                  props.carrito[c.codigoSku] ? null : (
                                    <div className="whim">
                                      <div className="image">
                                        <img
                                          style={c.agotado == 1 ? { opacity: 0.3 } : {}}
                                          className="img-product"
                                          src={
                                            Constants.URL_BASE +
                                            "/image/" +
                                            c.codigoSku.trim() +
                                            ".png"
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = noimage;
                                          }}
                                        ></img>
                                      </div>
                                      <div className="actions">
                                        <div className="header">
                                          <p>{c.nombre}</p>
                                          <p>
                                            {" $" +
                                              new Intl.NumberFormat("en", {
                                                numberingSystem: "latn",
                                                style: "decimal",
                                                currency: "COP",
                                              }).format(c.precio)}
                                          </p>
                                        </div>
                                        {props.carrito[c.codigoSku] ? (
                                          <div className="controls">
                                            <button
                                              role="button"
                                              onClick={() => handleMenosProducto(c)}
                                            >
                                              <img
                                                src={minus}
                                                alt="icon minus"
                                                width="15px"
                                                height="15px"
                                              ></img>
                                            </button>
                                            <input
                                              type="number"
                                              minLength="0"
                                              maxLength="99"
                                              onChange={(e) =>
                                                handleChangeCantidadProducto(
                                                  c,
                                                  e.target.value
                                                )
                                              }
                                              value={props.carrito[c.codigoSku].cantidad}
                                            />
                                            <button
                                              role="button"
                                              onClick={() => handleMas(c)}
                                            >
                                              <img
                                                src={plus}
                                                alt="icon plus"
                                                width="15px"
                                                height="15px"
                                              ></img>
                                            </button>
                                          </div>
                                        ) : (
                                          <button
                                            className="add"
                                            style={{ backgroundColor: props.color }}
                                            onClick={() => handleMasProducto(c)}
                                          >
                                            Agregar
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                ))}
                              </div>
                            )}
                          </>
                        )}


                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                  {props.usuario.accesoEmpleado == 1 ? (
                    <>
                      {total - ((total * props.usuario.descuento) / 100) < topPedido ? (
                        <div className="carrito-validacion">
                          <div>
                            <img
                              src={error}
                              alt="icon plus"
                              width="20px"
                              height="20px"
                            ></img>
                          </div>
                          <div className="contenido">
                            <p style={{ color: "red" }}>
                              <b>
                                El valor mínimo de envío es de
                                {" $" +
                                  new Intl.NumberFormat("es-CO", {
                                    style: "decimal",
                                    currency: "COP",
                                  }).format(topPedido)}
                              </b>
                            </p>
                            <p>
                              Completa tu pedido para que disfrutes todos los sabores de
                              SuperRicas.
                            </p>
                          </div>
                        </div>

                      ) : (
                        <React.Fragment></React.Fragment>

                      )}
                    </>
                  ) : (
                    <>
                      {total < topPedido ? (
                        <div className="carrito-validacion">
                          <div>
                            <img
                              src={error}
                              alt="icon plus"
                              width="20px"
                              height="20px"
                            ></img>
                          </div>
                          <div className="contenido">
                            <p style={{ color: "red" }}>
                              <b>
                                El valor mínimo de envío es de
                                {" $" +
                                  new Intl.NumberFormat("es-CO", {
                                    style: "decimal",
                                    currency: "COP",
                                  }).format(topPedido)}
                              </b>
                            </p>
                            <p>
                              Completa tu pedido para que disfrutes todos los sabores de
                              SuperRicas.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </>
                  )}

                </div>
              </div>
              {alerta ? <AlertModal alerta={alerta} updateAlerta={setAlerta} /> : null}
            </div>
        }
      </div>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
    carrito: selectActiveCarrito(state)
  };
};

export default withRouter(
  connect(mapStateToProps, { ActualizarCarrito, ActualizarUsuario })(Carrito)
);


