import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import noimage from '../../assets/img/noimage.png';
import nav from '../../assets/icon/nav.svg';
import logo_III  from '../../assets/img/logo_III.png';
import logo from "../../assets/img/todo_rico_sf.png";




const Navbar = (props) => {

    const handleRedirect = item => {
        //props.history.replace(item, "urlhistory");
        window.location.href = item;
    }

    const handleMenu= () => {
        document.querySelector(".menu").classList.toggle("show-menu");  
    }

    return (
      <div className="nav-header" id="nav-hidden">
        <div className="nav-content">
          <div className="subnav">
            <div className="nav-logo">
              <img
                onClick={() => handleRedirect("/")}
                src={logo_III}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = noimage;
                }}
              ></img>
            </div>
            <div className="icon"   >
            <img
              src={logo}
              alt="icon menu"
              width="70px"
              height="55px"
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect("/")}
            />
          </div>
          </div>
          
         
        </div>
      </div>
    );
}


export default withRouter(Navbar);