import React, { useState, useEffect } from "react";
import Sub_nav from "../navbar/Sub_nav";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

import success from '../../assets/icon/check.svg';
import info from '../../assets/icon/info.svg';
import warning from '../../assets/icon/warning.svg';
import error from '../../assets/icon/error.svg';


import { connect } from "react-redux";
import { selectActiveUser } from "../../store/usuario/reducer";

const query = require('query-string');

const RespuestaCPV = (props) => {

  const [params, setParams] = useState()
  
  const handleParams = () => {
    setParams(query.parse(props.location.search))
  }

  useEffect(() => {
    handleParams()
  }, []);

  return (
    <React.Fragment>
        <div className="navbar navcarrito">
          <Navbar item={3} />
            <Sub_nav />
        </div>
        <div className="layout">
          <div className="datos">
              <div className="card">
                  <div className="info">
                      {params ? <React.Fragment>
                        <p className="titulo-cpv"><b>Detalle de la transacción</b></p>
                        <div className="descripcion-cpv">
                            <div className="card-detalle" style={{
                                boxShadow:"5px 10px 20px 3px " + (params.aprobado=="A" ? "green" : (params.aprobado=="R" ? "red" : "blue"))
                              }}>
                              <img src={params.aprobado=="P" ? info : (params.aprobado=="A" ? success : error ) }></img>
                              <p style={{fontWeight:"bold",paddingBottom:"15px"}}> TRANSACCIÓN <span style={{color: (params.aprobado=="A" ? "green" : (params.aprobado=="R" ? "red" : "blue"))}}>{params.aprobado=="A" ? "APROBADA" : (params.aprobado=="R" ? "RECHAZADA" : "PENDIENTE")}</span></p> 
                              <p><b>CONVENIO: </b> <span style={{color:"#6D6363"}}>AV VILLAS</span></p>
                              <p><b>NIT CONVENIO: </b> <span style={{color:"#6D6363"}}>860035827</span></p>
                              <p><b>CLIENTE:</b> <span style={{color:"#6D6363"}}>{props.usuario.nombre + " " + props.usuario.apellido}</span></p>
                              <p><b>DOCUMENTO:</b> <span style={{color:"#6D6363"}}>{props.usuario.doc}</span></p>
                              <p><b>REF. PAGO:</b> <span style={{color:"#6D6363"}}>{params.ref2 ? params.ref2 : "N/A"}</span></p>
                              <p><b>REF. PAGO II:</b> <span style={{color:"#6D6363"}}>{params.uuid ? params.uuid : "N/A"}</span></p>
                              <p><b>VALOR:</b> <span style={{color:"#6D6363"}}>{("$" +
                                new Intl.NumberFormat("en", {
                                  numberingSystem: "latn",
                                  style: "decimal",
                                  currency: "COP",
                                }).format(params.valor ? params.valor : 0))}</span>
                              </p>
                              <p><b>ESTADO: </b><span style={{color:(params.aprobado=="A" ? "green" : (params.aprobado=="R" ? "red" : "blue"))}}>{params.aprobado=="A" ? "APROBADA" : (params.aprobado=="R" ? "RECHAZADA" : "PENDIENTE")}</span></p> 
                              <div className="alert-buttons" style={{paddingTop:"50px"}}>
                                  <button type="submit" className="btn-large" onClick={e => props.history.push("/catalogo")}
                                  style={{backgroundColor:(params.aprobado=="A" ? "green" : (params.aprobado=="R" ? "red" : "blue")),width:"30%"}}>Finalizar</button>
                              </div>
                            </div>
                        </div>  
                      </React.Fragment> : null }
                  </div>
              </div>
            </div>
        </div>
      <Footer />
    </React.Fragment>
  );
};

const mapStateProps = (state) =>{
  return {
    usuario: selectActiveUser(state)
  }
}

export default connect(mapStateProps)(RespuestaCPV);
