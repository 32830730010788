import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Constants from "../../constants/global";
import axios from "axios";
import Auth from "../../helpers/Auth";

import plus from "../../assets/img/agregar.png";
import edit from "../../assets/icon/editar.ico";
import Sub_nav from "../navbar/Sub_nav";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Progress from "../util/Progress";
import EditModal from "../modal/EditModal";
import AlertModal from "../modal/AlertModal";
import eliminar from "../../assets/icon/eliminar_dir.svg";

// REDUX
import { selectActiveUser } from "../../store/usuario/reducer";

const Datos = (props) => {
  const [alerta, setAlerta] = useState();
  const [carrito, setCarrito] = useState({});
  const [cantidad, setCantidad] = useState(0);
  const [editar, setEditar] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState();
  const [valido, setValido] = useState(true);
  const [item, setItem] = useState();

  // let cliente = props.usuario

  const handleEdit = (state, item) => {
    console.log(state, item)
    if (state == 2) {
      if (valido) {
        setEditar(state);
        setItem(item);
      }
    } else {
      setEditar(state);
    }
  };

  const handelDelete = (item) => {
    setAlerta({
      tipo: "warning",
      titulo: "Eliminar dirección.",
      mensaje: "¿Esta seguro que desea eliminar esta dirección?",
      btnOk: "Si, eliminar",
      btnCancel: "No, cancelar",
      fnOk: function () {
        let url = Constants.URL_BASE + "Cliente/Direccion";

        axios
          .delete(
            url,
            { data: { direccionId: item.id } },
            {
              headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
              },
            }
          )
          .then((response) => {
            window.location.reload(true);
          })
          .catch((err) => {
            setAlerta({
              tipo: "error",
              titulo: "Eliminar dirección.",
              mensaje: "No se puede eliminar esta dirección",
              btnOk: "Aceptar",
              btnCancel: null,
              fnOk: function () {},
              fnCancel: null,
            });
          });
      },
      fnCancel: function () {},
    });
  };

  const getResponse = (response) => {
    if (response) {
      setLoader(true);
      let clientUpdate = props.usuario;
      let url =
        Constants.URL_BASE + Constants.WSACTUALIZARDATOS + clientUpdate.id;
      axios
        .put(
          url,
          {
            nombres: clientUpdate.nombre,
            apellidos: clientUpdate.apellido,
            email: clientUpdate.email,
            clave: clientUpdate.clave,
            telefono: clientUpdate.telefono,
          },
          {
            headers: {
              Authorization: `Bearer ${clientUpdate.token}`,
            },
          }
        )
        .then((response) => {
          setLoader(false);
          setMessage(response.data.message);
          setTimeout(() => {
            setMessage(null);
          }, 3000);
        })
        .catch((err) => {});
    }
    setEditar(false);
  };

  const getCantidad = () => {
    var cant = 0;
    let cart = JSON.parse(localStorage.getItem("carrito")) || {};
    Object.keys(cart).map((item, i) => {
      cant += cart[item].cantidad;
    });
    setCantidad(cant);
  };

  useEffect(() => {
    setCarrito(JSON.parse(localStorage.getItem("carrito")) || {});
    getCantidad();
    const validarEdicion = () => {
      let url =
        Constants.URL_BASE + Constants.WSVALIDAREDICION + props.usuario.doc;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${Auth.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.valido) setValido(res.data.valido);
        });
    };
    validarEdicion();
  }, [props.usuario]);

  return (
    <React.Fragment>
      <div className="navbar navcarrito">
        <Navbar item={3} />
        <Sub_nav cantidad={cantidad} />
      </div>
      <div className="layout">
        <div className="datos">
          {message ? <label>{message}</label> : null}
          {loader ? (
            <Progress color={"#0194C5"}></Progress>
          ) : (
            <div>

              <div className="card">
                <div className="info">
                    <h3>Bogotá: (+571) 2670410 - Línea gratuita nacional: 018000423770
                        Correo: servicioalclientenacional@superricas.com
                    </h3>
                </div>
              </div> 
              <br />
              <br />
              <div className="card">
                <a className="edit" onClick={() => handleEdit(1)}>
                  <img
                    src={edit}
                    alt="icon delete"
                    width="30px"
                    height="30px"
                  ></img>
                </a>

                <h3>Datos personales</h3>
                <div className="info">
                  <p>
                    <b>Nombre:</b>{" "}
                    {props.usuario.nombre + " " + props.usuario.apellido}
                  </p>
                  <p>
                    <b>Documento:</b>{" "}
                    {props.usuario.tipoDoc + " " + props.usuario.doc}
                  </p>
                  <p>
                    <b>Correo:</b> {props.usuario.email}
                  </p>
                  <p>
                    <b>Teléfono:</b> {props.usuario.telefono}
                  </p>
                </div>
              </div>
              <br />
              <br />
              <div className="card">
                {/* {valido ? ( */}
                <a
                  style={{ opacity: valido ? "1" : "0.3" }}
                  className="edit"
                  onClick={() => handleEdit(3)}
                >
                  <img
                    src={plus}
                    alt="icon nuevo"
                    width="30px"
                    height="30px"
                  ></img>
                </a>
                {/* ) : null } */}
                <h3>Mi dirección</h3>
                {props.usuario && (
                  <React.Fragment>
                    {props.usuario.direcciones.map((item, i) => (
                      <div
                        className="info"
                        style={{
                          borderBottom: "1px solid #ebebeb",
                          paddingBottom: "10px",
                        }}
                      >
                        <h2>
                          <b>{item.identificador}</b>
                        </h2>
                        <p>
                          <b>Dirección:</b> {item.direccion}
                        </p>
                        {item.referencia.split("-").length == 2 && (
                          <>
                            <p>
                              <b>Barrio:</b> {item.referencia.split("-")[0]}
                            </p>
                            <p>
                              <b>Referencia:</b> {item.referencia.split("-")[1]}
                            </p>
                          </>
                        )}

                        {item.referencia.split("-").length == 3 && (
                          <>
                            <p>
                              <b>Barrio:</b> {item.referencia.split("-")[0]} -{" "}
                              {item.referencia.split("-")[1]}
                            </p>
                            <p>
                              <b>Referencia:</b>{" "}
                              {item.referencia.split("-")[2] &&
                                item.referencia.split("-")[2]}
                            </p>
                          </>
                        )}

                        <p>
                          <b>Municipio:</b> {item.municipio}
                        </p>
                        <p>
                          <b>Departamento:</b> {item.departamento}
                        </p>
                        <div className="btn">
                          <a
                            style={{ opacity: valido ? "1" : "0.3" }}
                            onClick={() => handleEdit(2, item)}
                          >
                            <img
                              src={edit}
                              alt="icon edit"
                              width="30px"
                              height="30px"
                            ></img>
                          </a>
                          <a onClick={() => handelDelete(item)}>
                            <img
                              src={eliminar}
                              alt="icon delete"
                              width="30px"
                              height="30px"
                            ></img>
                          </a>
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
              <div className="panel-button">
                <a
                  className="seguir-comprando"
                  onClick={(e) => props.history.push("/")}
                >
                  Seguir comprando
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      {editar ? (
        <EditModal
          editar={editar}
          item={item}
          callback={getResponse.bind(this)}
        />
      ) : null}

      {alerta ? <AlertModal alerta={alerta} updateAlerta={setAlerta} /> : null}

      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
  };
};

export default connect(mapStateToProps)(Datos);
