import React , { useState, useEffect } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

import Progress from '../util/Progress';
import DireccionPlaces from '../util/DireccionPlaces';
import DatosPersonales from './DatosPersonales';
import Maqueta from '../maqueta/Maqueta';
import Auth from '../../helpers/Auth';
import slidefondo from '../../assets/img/slidefondo.png';
import Swal from 'sweetalert2'
const publicIp = require('public-ip')

var sectionStyle = {
    backgroundSize: "cover",
    backgroundImage: "url(" + slidefondo + ")"
};

const Cobertura = ({history}) => {

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState({});
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [barrios, setBarrios] = useState([]);
    const [Cobertura, setCobertura] = useState(true); 
    const [departamento, setDepartamento] = useState("NONE");
    const [municipio, setMunicipio] = useState("NONE");
    

    const [direccion, setDireccion] = useState();  
    const [referencia, setReferencia] = useState();
    const [barrio, setBarrio] = useState();
    const [complemento, setComplemento] = useState();
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const [editEmploy, setEditEmploy] = useState()
  
    let data = {departamento:null,  municipio:null, barrio:null,   direccion:''  }

    const getDepartamentos = () =>{
        const departamentoData = () => {
            let url = Constants.URL_BASE + Constants.WSDEPARTAMENTOS + "?co=1" 
            axios.get(url).then(res=>{
                let data = res.data || [];
                setDepartamentos(data);
            })
        };
        departamentoData();
    }

    const getMunicipios = () =>{
        const municipiosData = () => {
            let url = Constants.URL_BASE + Constants.WSMUNICIPIO + data.departamento + "?co=1"
            axios.get(url).then(res=>{
                let data = res.data || [];
                setMunicipios(data);
                getBarrios(data.id);
            })
        };
        municipiosData();
    }

    const getBarrios = (municpioId) =>{
        let url = Constants.URL_BASE + Constants.WSBARRIO +  municpioId;
        axios.get(url).then(res=>{
            let data = res.data || [];
            setBarrios(data);
        })
    }


    const handleChangeDep = (item) => {

        if(item != "NONE"){
            var depto = JSON.parse(item);
            data.departamento=depto.id;
            setDepartamento(item);
            setMunicipio("NONE");
            getMunicipios();
            setDireccion("");
            setBarrio("");
        }else{
            setDepartamento(item);
            setMunicipios([]);
            setMunicipio("NONE");
            setDireccion("");
            setBarrio("");
        }
    }

    const handleChangeMun = (item) =>{
        if(item != "NONE"){
            var muni = JSON.parse(item);
            setMunicipio(item);
            data.municipio=muni;
            setDireccion("");
            getBarrios(muni.id);
            setDireccion("");
            setBarrio("");
        }else{
            getBarrios([]);
            setMunicipio(item);
            setDireccion("");
            setDireccion("");
            setBarrio("");
        }
    }

    const handleCobertura = () => {

        var err = {};
        if(!departamento){
            err['Departamento'] = "El departamento es obligatorio.";
            setError(err);
        }

        if(!municipio) {
            err['Municipio'] = "El municipio es obligatorio.";
            setError(err);
        }

        if(!direccion) {
            err['Direccion'] = "la direccion es obligatoria.";
            setError(err);
        }

        if(!barrio) {
            err['Barrio'] = "El barrio es requerido.";
            setError(err);
        }

        if(Object.keys(err).length == 0) {
            setError('');
            setReferencia(barrio + " - " + complemento);
            if(editEmploy > 0){
                var depto = JSON.parse(departamento);
                var muni = JSON.parse(municipio);
                const options = {
                    method: 'POST',
                    url: Constants.URL_BASE + 'Cliente/Direccion',
                    data: {
                      clienteId: parseInt(editEmploy),
                      departamentoId: Number(depto.id),
                      municipioId: Number(muni.id),
                      barrioId: 0,
                      direccion: direccion,
                      referencia: barrio + " - " + complemento,
                      latitud: latitud,
                      longitud: longitud,
                      identificador: 'hogar'
                    }
                  };
                  axios.request(options).then(function (response) {
                    console.log(response);
                    Swal.fire({
                        title: 'Direccion actualizada !',
                        text: 'Para finalizar tu proceso te pediremos que crees una contraseña',
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: 'Continuar',
                        confirmButtonColor : "#DE0021",
                      }).then((result) => {
                        if (result.isConfirmed) {
                            history.push("/recuperar-clave");
                        }
                      })
                  }).catch(function (error) {
                    if(error.response){
                        setError(err.response.data.errors)
                    }  
                  });
               
            }else{
                setCobertura(false);
            }
        }
    }



    const handleNoCobertura = () =>{
        history.push('/no-cobertura')
    }


    useEffect(() => {
        getDepartamentos()
        setEditEmploy(localStorage.getItem("editEmployed"))
    }, [])

    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <Maqueta />
                <div className="login-form" id="login-form">
                    <div className="main">
                    <h3 className="large">Registro</h3>                                                        
                    <form>
                        {loader ? <Progress color={"#0194C5"}></Progress> : (
                            <React.Fragment>
                                {!Cobertura ? (
                                    <React.Fragment>
                                        <DatosPersonales cobertura={
                                                {
                                                    'departamento':departamento ? JSON.parse(departamento).id : 0, 
                                                    'municipio':municipio ? JSON.parse(municipio).id : 0,
                                                    'barrio': 0,
                                                    'direccion': direccion, 
                                                    'referencia': referencia,
                                                    'latitud': latitud, 
                                                    'longitud': longitud
                                                }
                                            } 
                                            updateCobertura={setCobertura}/>
                                    </React.Fragment>
                                ) : (
                                <React.Fragment>
                                    {departamentos ? (
                                    <React.Fragment>                                       
                                        <div>
                                            <div className="form-control">
                                                <label>Departamento <span>*</span>:</label>
                                                <select 
                                                    className={error.Departamento ? "input-error" : null}
                                                    name="departamentos" 
                                                    value={departamento} 
                                                    onChange={e => handleChangeDep(e.target.value)}>
                                                    <option selected="true" value="NONE">Seleccione departamento</option>
                                                    {departamentos.map((item,i)=>(
                                                        <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                    ))}
                                                </select> 
                                                { error.Departamento ? (
                                                    <span className="error">
                                                        <b>{error.Departamento}</b>
                                                    </span>
                                                ) : null }
                                            </div>
                                            
                                            <div className="form-control"> 
                                                <label>Municipio <span>*</span>:</label>
                                                <select 
                                                    className={error.Municipio ? "input-error" : null}
                                                    name="municipios" 
                                                    value={municipio} 
                                                    onChange={e => handleChangeMun(e.target.value)}>
                                                    <option selected="true" value="NONE">Seleccione un municipio</option>
                                                    {municipios.map((item,i)=>(
                                                        <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                    ))}
                                                </select> 
                                                { error.Municipio ? (
                                                    <span className="error">
                                                        <b>{error.Municipio}</b>
                                                    </span>
                                                ) : null }
                                            </div>
                                           
                                            {municipio != 'NONE' ? (
                                                <React.Fragment>
                                                     <DireccionPlaces 
                                                        barrios={barrios}
                                                        departamento={departamento}
                                                        municipio={municipio}
                                                        direccion={direccion}
                                                        barrio={barrio}
                                                        complemento={complemento}
                                                        latitud={latitud}
                                                        longitud={longitud}
                                                        updateDireccion={setDireccion}
                                                        updateBarrio={setBarrio}
                                                        updateComplemento={setComplemento}
                                                        updateLatitud={setLatitud}
                                                        updateLongitud={setLongitud}
                                                        error={error}/>
                                                </React.Fragment>
                                            ) : null} 

                                            {direccion ? (
                                                <React.Fragment>
                                                    <button type="button" className="btn-large" onClick={() => handleCobertura()}>Continuar registro</button>
                                                    <button type="button" className="btn-large outline" onClick={e => history.push('/login')}>Cancelar</button>
                                                </React.Fragment>
                                            ) : (
                                                
                                            <div className="form-link ">
                                                <a onClick={e => handleNoCobertura()}>
                                                Si no encuentras tu ciudad, déjanos tus datos haciendo clic aquí y te avisaremos cuando ampliemos la cobertura.                                                
                                                </a>
                                                
                                                <div className="form-link2 ">
                                                <br></br>
                                                <a>
                                                Si eres persona jurídica, puedes comunicarte al siguiente número 2670410 para ser atendido por nuestros canales institucionales.                      </a>
                                                </div>
                                                <button type="button" className="btn-large outline" onClick={e => history.push('/login')}>Cancelar</button>
                                            </div>
                                            )}    
                                           
                                        </div> 
                                    </React.Fragment>                                 
                                    ) : null}                                                                           
                                </React.Fragment>
                            )}                                
                            </React.Fragment>
                        )}
                        
                    </form>   
                    </div>
                                
                </div>
            </div>
        </div>
    )
}

export default Cobertura;