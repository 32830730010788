import React, { useEffect, useRef } from 'react'

// ICONOS
import close from '../../assets/icon/close_grey.svg';
import imarker from '../../assets/icon/marker.svg';

// MAPA
let map;

// PINTA EL MAPA Y EL MARKER
async function loadMap(mapRef, direcciones, updateMultipleDireccion){
    
    var bounds = new window.google.maps.LatLngBounds();

    var lat = direcciones[0].geometry.location.lat();
    var lon = direcciones[0].geometry.location.lng();
    map = new window.google.maps.Map(mapRef.current, {
        zoom: 17,
        disableDefaultUI: true
    });

    var styleArray = [
        {
          featureType: "all",
          stylers: [
            { visibility: "off" }
          ]
        },
        {
          featureType: "road",
          stylers: [
            { visibility: "on" }
          ]
        }
    ];

    map.setOptions({styles: styleArray});

    var icon = {
        url: imarker,
        scaledSize: new window.google.maps.Size(40, 40),
        origin: new window.google.maps.Point(0,0),
        anchor: new window.google.maps.Point(0, 0)
    };

    direcciones.map((item, i) => {
        console.log(item);
        var latitude = item.geometry.location.lat();
        var longitude = item.geometry.location.lng();
        var myLatlng = new window.google.maps.LatLng(latitude, longitude);
        let marker = new window.google.maps.Marker({
            position: myLatlng,
            draggable: true,
            title: item.formatted_address,
            icon: icon
        });
        bounds.extend(marker.position);

        window.google.maps.event.addListener(marker, 'click', () => {
            updateMultipleDireccion(item);
        });  

        marker.setMap(map);
    })

    map.fitBounds(bounds);
}

const MultipleDireccion = (props) => {

    const mapRef = useRef(null);

    const handleClose = () => {
        document.querySelector(".modal-multiple").classList.remove("show-modal");
    }

    useEffect(() => {
        document.querySelector(".modal-multiple").classList.toggle("show-modal");

        setTimeout(function(){
            loadMap(mapRef, props.multipleDireccion, props.updateMultipleDireccion);
        }, 300);
    }, [props.multipleDireccion])    

    return (
        <div className="modal modal-multiple">
            <div className="modal-content table-content">
                <div className="title">
                    <p>Seleccione la Dirección</p>
                </div>
                <div className="content">
                    <React.Fragment>
                        <p className="title-map">Hemos encontrado multiples direcciones, selecciona el pin que mas se ajuste a tu dirección.</p>
                        <div 
                            className="map"
                            ref={mapRef}
                            style={{ width: '100%', height: '200px'}}>
                        </div>
                    </React.Fragment>
                    <button type="button" className="btn-large outline" onClick={() => handleClose()}>Cancelar</button>
                </div>
            </div> 
        </div>
    )
}

export default MultipleDireccion
