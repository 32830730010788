import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import axios from "axios";

// REDUX
import ActualizarUsuario from "../../store/usuario/action";
import { selectActiveUser } from "../../store/usuario/reducer";
import Auth from "../../helpers/Auth";
import plus from "../../assets/img/agregar.png";
import dolar from "../../assets/icon/dolar.svg";
import card from "../../assets/icon/card.svg";
import payuIcon from '../../assets/img/payuIcon.jpeg'
import EditModal from "../modal/EditModal";
import { FcMoneyTransfer } from "react-icons/fc";
import moment from "moment";
import 'moment/locale/es';

const styles = {
  selectPay: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#37E56A",
  },
};

const CheckOut = (props) => {
  const [fechaEntrega, setFechaEntrega] = useState();
  const [direcciones, setDirecciones] = useState([]);
  const [direccion, setDireccion] = useState();
  const [identificador, setIdentificador] = useState();
  const [editar, setEditar] = useState(false);
  const [pay, setPay] = useState(true);
  const [selectedPay, setSelectedPayu] = useState(false)
  const [selectedDescountSalari, setSelectedDescountSalari] = useState(false)
  const [selectedPse, setSelectedPse] = useState(false)
  const [accesoEmpleado, setAccesoEmpleado] = useState(0)

  const handleEdit = (state) => {
    setEditar(state);
  };

  const verificarFecha = () => {
    let cliente = props.usuario;
    let url = Constants.URL_BASE + Constants.WSFECHAENTREGA;
    axios.get(url).then((response) => {
      setFechaEntrega(response.data.texto);
      cliente.fecha = response.data.fecha;
      props.ActualizarUsuario(cliente);
    });
  };

  const handleObservacion = (value) => {
    let cliente = props.usuario;
    cliente.observacion = value;
    props.ActualizarUsuario(cliente);
  };

  const handleDireccion = (value) => {
    let selDireccion = JSON.parse(value);
    let cliente = props.usuario;
    cliente.direccionId = selDireccion.id;
    setDireccion(selDireccion);
    setIdentificador(value);

    Auth.setUbicacion({
      direccion: value,
      departamentoId: selDireccion.departamentoId,
      departamento: selDireccion.departamento,
      municipioId: selDireccion.municipioId,
      municipio: selDireccion.municipio,
    });

    props.ActualizarUsuario(cliente);
    //props.validatePedido();
  };

  const getResponse = (response) => {
    setEditar(false);
    if (response) {
      let url = Constants.URL_BASE + Constants.WSACTUALIZARDATOS;
      axios.get(url).then((res) => {
        props.ActualizarUsuario(res.data);
        let usuario = res.data;
        console.log(usuario);
        setDirecciones(usuario.direcciones);
        setIdentificador(
          JSON.stringify(usuario.direcciones[usuario.direcciones.length - 1])
        );
        handleDireccion(
          JSON.stringify(usuario.direcciones[usuario.direcciones.length - 1])
        );
      });
    }
  };

  const changeItemSelected = (value) => {
    console.log(value);
    if (value == 1) {
      setSelectedPayu(false)
      setSelectedPse(false)
      setPay(true)
      setSelectedDescountSalari(false)
      props.setPay(false)
      props.setMetodoOnline("EFECTIVO")
      localStorage.setItem("methodOnline", 1)
      return
    }
    if (value == 2) {
      setSelectedPayu(true)
      setSelectedPse(false)
      setPay(false)
      setSelectedDescountSalari(false)
      props.setPay(true)
      props.setMetodoOnline("CONVENIO_PAYU")
      localStorage.setItem("methodOnline", 2)
      return
    }
    if (value == 3) {
      setSelectedPayu(false)
      setSelectedPse(true)
      setPay(false)
      setSelectedDescountSalari(false)
      props.setPay(true)
      props.setMetodoOnline("CONVENIO_AVVILLAS")
      localStorage.setItem("methodOnline", 1)
      return
    }
    if (value == 4) {
      setSelectedPayu(false)
      setSelectedPse(false)
      setPay(false)
      setSelectedDescountSalari(true)
      props.setPay(false)
      props.setMetodoOnline("NOMINA")
      localStorage.setItem("methodOnline", 1)
      return
    }
  }

  useEffect(() => {
    props.setPay(false)
    verificarFecha();
    setAccesoEmpleado(localStorage.getItem("ingresoEmpleado"))
    const ubicacion = Auth.getUbicacion();
    setDirecciones(props.usuario.direcciones);
    setIdentificador(ubicacion.direccion);
    handleDireccion(ubicacion.direccion);
  }, []);


  return (
    <React.Fragment>
      <br />
      <div className="check-content">
        <div className="item" style={{ "padding-top": "15px" }}>
          <p className="opc">Cliente:</p>
          <p className="val">
            {props.usuario ? (
              <React.Fragment>
                {props.usuario.nombre} {props.usuario.apellido}
              </React.Fragment>
            ) : (
              ""
            )}
          </p>
        </div>
        <hr style={Constants.style} />
        <div className="item" style={{ "padding-top": "15px" }}>
          <p className="opc">Dirección:</p>
          <p className="val">
            {direcciones.length > 0 ? (
              <React.Fragment>
                <select
                  value={identificador}
                  onChange={(e) => handleDireccion(e.target.value)}
                >
                  <option disabled selected>
                    Seleccione la dirección
                  </option>
                  {direcciones.map((item, i) => (
                    <option value={JSON.stringify(item)}>
                      {item.identificador}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            ) : null}
          </p>
        </div>
        <div className="item" style={{ "padding-top": "15px" }}>
          <p className="opc">
            <a
              className="edit"
              onClick={() => handleEdit(3)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.8rem",
                marginTop: "10px",
              }}
            >
              <img src={plus} alt="icon nuevo" width="30px" height="30px"></img>
              Agregar
            </a>
          </p>
          {direccion && (
            <p>
              {direccion.direccion +
                ", " +
                (direccion.referencia ? direccion.referencia + ", " : "") +
                direccion.municipio +
                " " +
                direccion.departamento}
            </p>
          )}
        </div>
        <hr style={Constants.style} />
        <div className="item" style={{ "padding-top": "15px" }}>
          <p className="opc">Fecha de entrega:</p>
          <span className="val"><br></br>{fechaEntrega}</span>
        </div>
        <div>

        </div>
        <hr style={Constants.style} />
        <div className="payment" style={{ "padding-top": "15px" }}>
          <p className="opc">Método de pago:</p>
          {/* <p className="val">Contraentrega, paga cuando recibas</p> */}
          <div className={accesoEmpleado == 1 ? "valEmployed" : "valEmployed"}>
          {accesoEmpleado == 1 ? null : (
            <div
              className="card"
              style={pay ? styles.selectPay : null}
              onClick={() => changeItemSelected(1)}
            >
              <div className="option">
                <img src={dolar} alt="dolar" />
                <p>Efectivo</p>
              </div>
            </div>
          )}
            {/* <div
              className="card"
              style={selectedPse ? styles.selectPay : null}
              onClick={() => changeItemSelected(3)}
            >
              <div className="option">
                <img src={card} alt="dolar" />
                <p>PSE</p>
              </div>
            </div> */}
            <div
              className="card"
              style={selectedPay ? styles.selectPay : null}
              onClick={() => changeItemSelected(2)}
            >
              <div className="option">
                <img src={payuIcon} alt="dolar" />
                <p>PayU</p>
              </div>
            </div>
            {accesoEmpleado == 1 ? (
              <div
              className="card"
              style={selectedDescountSalari ? styles.selectPay : null}
              onClick={() => changeItemSelected(4)}
            >
              <div className="option" >
                <FcMoneyTransfer size={50}/>
                <p>Descuento por nomina</p>
              </div>
            </div>
            ):null}
          </div>
        </div>
        <hr style={Constants.style} />
        <div className="item" style={{ "padding-top": "15px" }}>
          <input
            className="input"
            onChange={(e) => handleObservacion(e.target.value)}
            placeholder="Observaciones"
          ></input>
        </div>
      </div>
      {editar ? (
        <EditModal editar={editar} callback={getResponse.bind(this)} />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { ActualizarUsuario })(CheckOut)
);
