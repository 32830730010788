import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';

import Auth from '../../helpers/Auth';
import AlertModal from '../modal/AlertModal';

import address_blue from '../../assets/icon/address_blue.svg';

const CoberturaModal = (props) => {

    const [alerta, setAlerta] = useState();
    const [error, setError] = useState({});
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [departamento, setDepartamento] = useState("NONE");
    const [municipio, setMunicipio] = useState("NONE");

    const handleNoCobertura = () =>{
        props.history.push('/login')
    }

    const handleChangeDep = (item) => {
        var depto = JSON.parse(item);
        var ubicacion = Auth.getUbicacion();
        var carrito = Auth.getCarrito();

        if(Object.keys(ubicacion).length > 0 && Object.keys(carrito).length > 0){
            if(ubicacion.departamentoId != depto.id){
                setAlerta({
                    tipo: "warning",
                    titulo: "Alerta.",
                    mensaje: "Ha cambiado la ubicación registrada con anterioridad. Se evaluará la cobertura de su pedido.",
                    btnOk: "Aceptar",
                    fnOk: function(){},
                    fnCancel: null
                })    
            }
        }

        if(item != "NONE"){
            setDepartamento(item);
            setMunicipio("NONE");
            getMunicipios(depto.id);
        }else{
            setDepartamento(item);
            setMunicipio(item);
        }
        
    }

    const handleChangeMun = (item) =>{
        if(item != "NONE"){
            //var muni = JSON.parse(item);
            setMunicipio(item);
        }else{
            setMunicipio(item);
        }
    }

    const getDepartamentos = () =>{
        let url = Constants.URL_BASE + Constants.WSDEPARTAMENTOS + "?co=1";
        axios.get(url).then(res=>{
            let data = res.data || [];
            setDepartamentos(data);
        })
    }

    const getMunicipios = (depto_id) =>{
        let url = Constants.URL_BASE + Constants.WSMUNICIPIO + depto_id + "?co=1";
        axios.get(url).then(res=>{
            let data = res.data || [];
            setMunicipios(data);
        })
    }

    const handleClose = () => {
        props.showModal(false);
        document.querySelector(".modal-cobertura").classList.remove("show-modal");
    }

    const handleSaveLocation = () => {
        var err = {};
        if(departamento == "NONE"){
            err['Departamento'] = "El departamento es obligatorio.";
            setError(err);
        }

        if(municipio == "NONE") {
            err['Municipio'] = "El municipio es obligatorio.";
            setError(err);
        }

        if(Object.keys(err).length == 0) {
            var depto = JSON.parse(departamento);
            var muni = JSON.parse(municipio);
            Auth.setUbicacion({
                departamentoId: depto.id,
                departamento: depto.name,
                municipioId: muni.id,
                municipio: muni.name,
            });
            props.updateMunicipio(muni.name);
            handleClose();
            window.location.reload(true);
        }
       
    }


    useEffect(() => {
        document.querySelector(".modal-cobertura").classList.toggle("show-modal");
        getDepartamentos();
    }, [])

    return (
        <React.Fragment>
            <div className="modal modal-cobertura">
                <div className="modal-content table-content">       
                    <div className="content">
                        <div className="alert-content">
                            <img src={address_blue} alt="icon menu" width="60px" height="60px"></img>
                            <p className="alert-title">¿De que ciudad nos visita?</p>
                            <p className="alert-message">Por favor seleccione su ciudad actual.</p>
                        </div>
                        
                        <form>
                            <div className="form-control">
                                <label>Departamento <span>*</span>:</label>
                                <select 
                                    className={error.Departamento ? "input-error" : null}
                                    name="departamentos" 
                                    value={departamento} 
                                    onChange={e => handleChangeDep(e.target.value)}>
                                    <option selected="true" value="NONE">Seleccione departamento</option>
                                    {departamentos.map((item,i)=>(
                                        <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                    ))}
                                </select> 
                                { error.Departamento ? (
                                    <span className="error">
                                        <b>{error.Departamento}</b>
                                    </span>
                                ) : null }
                            </div>
                            
                            <div className="form-control"> 
                                <label>Municipio <span>*</span>:</label>
                                <select 
                                    className={error.Municipio ? "input-error" : null}
                                    name="municipios" 
                                    value={municipio} 
                                    onChange={e => handleChangeMun(e.target.value)}>
                                    <option selected="true" value="NONE">Seleccione un municipio</option>
                                    {municipios.map((item,i)=>(
                                        <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                    ))}
                                </select> 
                                { error.Municipio ? (
                                    <span className="error">
                                        <b>{error.Municipio}</b>
                                    </span>
                                ) : null }
                            </div>

                            <div className="form-link ">
                                <a onClick={e => handleNoCobertura()}>
                                    Si ya tiene una cuenta o desea registrarse, haz click aquí.
                                </a>
                            </div>

                            <button type="button" className="btn-large" onClick={e => handleSaveLocation()}>Aceptar</button>
                            <button type="button" className="btn-large outline" onClick={e => handleClose()}>Cancelar</button>
                        </form>
                    </div>
                </div>
            </div>
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
        </React.Fragment>
    )
}

export default withRouter(CoberturaModal);