import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "../../constants/firebase";
import * as Constants from "../../constants/global";
import axios from "axios";
import Auth from "../../helpers/Auth";
import Progress from "../util/Progress";
import Swal from 'sweetalert2'
// COMPONENTES
import Maqueta from "../maqueta/Maqueta";
import VerificarEmail from "./VerificarEmail";
import EditModal from "../modal/EditModal";
//REDUX
import ActualizarUsuario from "../../store/usuario/action";
import ActualizarCarrito from '../../store/carrito/action';
import ActualizarEmpleado from '../../store/empleado/action'

// IMAGENES
import logo_III from "../../assets/img/logo_III.png";
import logo from "../../assets/img/todo_rico_sf.png";
import novisibility from "../../assets/icon/hidden.svg";
import visibility from "../../assets/icon/visibility.svg";
import back from "../../assets/icon/back.svg";
import { selectActiveCarrito } from "../../store/carrito/reducer";


const Login = (props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [errorg, setErrorg] = React.useState("");
  const [errors, setErrors] = React.useState("");
  const [hidden, setHidden] = React.useState(false);
  const [slide, setSlide] = React.useState(false);
  const [verificar, setVerificar] = React.useState(false);
  const [loguinEmpleado, setLoguinEmpleado] = useState(false);
  const [movilEmpleado, setMovilEmpleado] = useState()
  const [editar, setEditar] = useState()
  const [message, setMessage] = useState();
  const [items, setItems] = useState();

  const handleRegistrar = () => {
    localStorage.setItem("editEmployed",0)
    setTimeout(() => {
      props.history.push("/cobertura");
    }, 200);
  };

  const handleRecuperarclave = (clienteId) => {
    if(clienteId == 0 || clienteId == undefined){
      props.history.push("/recuperar-clave");
    }else{
      props.history.push("/cobertura");
      localStorage.setItem("editEmployed", clienteId)
    }
  };

  const enviarCorreo = () => {
    setLoader(true)
    let url = Constants.URL_BASE + Constants.WSINGRESOEMPLEADO
    axios.post(url, {
      'email': email
    }).then((response) => {
      setLoader(false)
      setLoguinEmpleado(true)
    }).catch((err) => {
      setLoader(false);
      if (err.response.data.error) {
        Swal.fire({
          icon: 'error',
          title: "Email no encontrado.",
          text: err.response.data.error
        })
      }
    })
  }

  const handleSubmit = (event) => {
    Auth.removeCarrito();
    props.ActualizarCarrito({});
    setLoader(true);
    let url = Constants.URL_BASE + Constants.WSAUTH;
    axios
      .post(url, {
        email: email,
        clave: password,
      })
      .then((res) => {
        console.log(res);
        if (res.status == 200 && password == '1234') {
          console.log(res.data.cliente.direcciones.length);
          Swal.fire({
            title: 'Bienvenido a Super Ricas !',
            text: res.data.cliente.direcciones.length > 0 ? 'Para completar tu registro te pediremos que crees una contraseña' : 'Para completar tu registro te pediremos que actualices una información',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Continuar',
            confirmButtonColor : "#DE0021",
          }).then((result) => {
            if (result.isConfirmed) {
              if(res.data.cliente.direcciones.length > 0){
                handleRecuperarclave(0)
              }else{
                handleRecuperarclave(res.data.cliente.id)
              }
            }
          })
        } else {
          setMovilEmpleado(res.data.cliente.telefono)
          setLoader(false);
          setHidden(false);
          setSlide(true);
          if (res.data) {
            if (res.data.cliente.zonaventa == "40109" && res.data.cliente.accesoEmpleado == "Y") {
              Swal.fire({
                title: 'Como desea ingresar ?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Usuario',
                denyButtonText: `Empleado`,
                denyButtonColor: '#28A745'
              }).then((result) => {
                if (result.isConfirmed) {
                  let change = res.data.cliente
                  change.accesoEmpleado = 0
                  props.ActualizarUsuario(change);
                  Auth.setToken(res.data.token);
                  Auth.setUbicacion({
                    direccion: JSON.stringify(res.data.cliente.direcciones[0]),
                    departamentoId: res.data.cliente.direcciones[0].departamentoId,
                    departamento: res.data.cliente.direcciones[0].departamento,
                    municipioId: res.data.cliente.direcciones[0].municipioId,
                    municipio: res.data.cliente.direcciones[0].municipio,
                  });
                  props.history.replace("/");
                } else if (result.isDenied) {
                  enviarCorreo()
                  localStorage.setItem("ingresoEmpleado", 1)
                  localStorage.setItem("codeBodega",res.data.cliente.bodega)
                }
              })
            } else {
              let change = res.data.cliente
              change.accesoEmpleado = 0
              props.ActualizarUsuario(change);
              Auth.setToken(res.data.token);
              Auth.setUbicacion({
                direccion: JSON.stringify(res.data.cliente.direcciones[0]),
                departamentoId: res.data.cliente.direcciones[0].departamentoId,
                departamento: res.data.cliente.direcciones[0].departamento,
                municipioId: res.data.cliente.direcciones[0].municipioId,
                municipio: res.data.cliente.direcciones[0].municipio,
              });
              props.history.replace("/");
            }
            /* firebase
              .analytics()
              .logEvent(
                "login",
                res.data.cliente.doc + "-" + res.data.cliente.email
              ); */
          }
        }


      })
      .catch((err) => {
        setLoader(false);
        console.log(err)
        if (err.response.data.errors) {
          setErrors(err.response.data.errors);
        }

        if (err.response.data.error) {
          setErrorg(err.response.data.error);

          if (err.response.data.code == "NO_VERIFICADO") {
            setVerificar(true);
          }
        }
      });
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.clear()
    if (!slide) {
      if (document.querySelector(".login-content")) {
        document.querySelector(".login-content").classList.add("show");

        setTimeout(function () {
          setSlide(true);
          if (document.querySelector(".login-content"))
            document.querySelector(".login-content").classList.remove("show");
        }, 4000);
      }
    }
  }, []);

  // VISTAS
  return (
    <div className="login">
      <div className="login-content">
        <Maqueta />
        {message ? <label>{message}</label> : null}
        <div className="login-form" id="login-form">
          <a className="btn-back" href="/">
            <img src={back} alt="icon back" width="15px" height="15px"></img>
            <p>Volver a la tienda</p>
          </a>
          <div className="main">
            {loguinEmpleado ? (
              <form>
                <VerificarEmail email={email} empleado={loguinEmpleado} celular={movilEmpleado} />
              </form>
            ) : (
              <>
                {verificar ? (
                  <form>
                    <VerificarEmail email={email} />
                  </form>
                ) : (
                  <React.Fragment>
                    <div className="logo-img">
                      <img src={logo_III} alt="logo app"></img>
                      <img src={logo} alt="logo app"></img>
                    </div>
                    {loader ? (
                      <Progress color={"#2098de"} />
                    ) : (

                      <form onSubmit={handleSubmit}>
                        <React.Fragment>
                          <div
                            style={{ marginTop: "2rem" }}
                            className="form-control"
                          >
                            <label>
                              Correo o Identificación <span>*</span>:
                            </label>
                            <input
                              className={errors.Email ? "input-error" : null}
                              type="text"
                              placeholder="Correo o Identificación"
                              name="email"
                              id="email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            ></input>
                            {errors.Email ? (
                              <span className="error">
                                <b>{errors.Email}</b>
                              </span>
                            ) : null}
                          </div>
                          <div className="form-control">
                            <label>
                              Contraseña <span>*</span>:
                            </label>
                            <input
                              className={errors.Clave ? "input-error" : null}
                              type={hidden ? "text" : "password"}
                              placeholder="Contraseña"
                              name="password"
                              id="password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                            ></input>
                            {hidden ? (
                              <div>
                                <img
                                  className="button-hidden"
                                  src={visibility}
                                  onClick={(e) => setHidden(false)}
                                  alt="icon minus"
                                  width="15px"
                                  height="15px"
                                ></img>
                              </div>
                            ) : (
                              <div>
                                <img
                                  className="button-hidden"
                                  src={novisibility}
                                  onClick={(e) => setHidden(true)}
                                  alt="icon minus"
                                  width="15px"
                                  height="15px"
                                ></img>
                              </div>
                            )}

                            {errors.Clave ? (
                              <span className="error">
                                <b>{errors.Clave}</b>
                              </span>
                            ) : null}
                          </div>

                          {errorg.length > 0 ? (
                            <span className="error">
                              <p>{errorg}</p>
                            </span>
                          ) : null}

                          <div className="form-link">
                            <a onClick={(e) => handleRecuperarclave()}>
                              ¿Olvidé mi contraseña?
                            </a>
                          </div>

                          <button type="submit" className="btn-large ">
                            Ingresar
                          </button>
                          <button
                            type="button"
                            className="btn-large outline"
                            onClick={() => handleRegistrar()}
                          >
                            Registrarse
                          </button>

                          <div className="form-link">
                            {/* <a href="/manual" target="_blank">
                          Conocer más
                        </a> */}
                          </div>
                        </React.Fragment>
                      </form>

                    )}
                  </React.Fragment>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
   
  );
};

const mapStateToProps = (state) => {
  return ({
    carrito: selectActiveCarrito(state)
  })
}

export default withRouter(connect(mapStateToProps, { ActualizarUsuario, ActualizarCarrito })(Login));
