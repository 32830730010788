import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "../../constants/firebase";
import ReactPixel from 'react-facebook-pixel';
import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import Auth from "../../helpers/Auth";
import * as Constants from "../../constants/global";
import AlertModal from "../modal/AlertModal";
import axios from "axios";
import minus from "../../assets/icon/minus.svg";
import plus from "../../assets/icon/plus.svg";
import noimage from "../../assets/img/logo_III.png";

//REDUX
import ActualizarCarrito from "../../store/carrito/action";
import { selectActiveCarrito } from "../../store/carrito/reducer";
import { selectActiveUser } from "../../store/usuario/reducer";

// import TagManager from 'react-gtm-module'

//iconos 
import { BsSunFill } from "react-icons/bs";



const CardProducto = (props) => {
  const [alerta, setAlerta] = useState();
  const [cantidad, setCantidad] = useState(1);
  const [empleado, setEmpleado] = useState(0)

  useEffect(() => {
    console.log(props.producto);
    setEmpleado(localStorage.getItem("ingresoEmpleado"));
    /*   const options = {
        autoConfig: true,
        debug: false, 
      };
      ReactPixel.init('208980407390923', advancedMatching, options); */
  }, [])

  ReactPixel.init('208980407390923', {}, { debug: true, autoConfig: false });
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');

  const validate = (id, callBack) => {
    if (id == 5 || id == 6) {
      var cliente = props.usuario;
      if (cliente) {
        if (!cliente.fechaMayorEdad && id == 5) {
          setAlerta({
            tipo: "warning",
            titulo: "¿Usted es mayor de edad?",
            mensaje:
              "Recuerde que este tipo de productos solo puede ser vendidos a mayores de edad.",
            btnOk: "Si",
            btnCancel: "No",
            fnOk: function () {
              var cliente = props.usuario;
              if (cliente) {
                let fa = new Date();
                let fe = new Date(
                  fa.getFullYear(),
                  fa.getMonth(),
                  fa.toLocaleString("es-CO", { day: "numeric" }),
                  fa.getHours(),
                  fa.getMinutes(),
                  fa.getMilliseconds()
                );
                axios
                  .put(
                    Constants.URL_BASE +
                    Constants.WSACTUALIZARMAYOR +
                    cliente.id,
                    {
                      fechaMayorEdad: fe.toISOString(),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                    }
                  )
                  .then((response) => {
                    cliente.fechaMayorEdad = fe.toISOString();
                    props.ActualizarUsuario(cliente);
                    document
                      .querySelector(".modal-sucursal")
                      .classList.remove("show-modal");
                    callBack(true);
                  })
                  .catch((err) => { });
              }
            },
            fnCancel: function () {
              callBack(false);
            },
          });
        } else if (!cliente.fechaMayor14 && id == 6) {
          setAlerta({
            tipo: "warning",
            titulo: "¿Usted es mayor de 14 años?",
            mensaje:
              "Recuerde que este tipo de productos solo puede ser vendidos a mayores de 14 años.",
            btnOk: "Si",
            btnCancel: "No",
            fnOk: function () {
              var cliente = props.usuario;
              if (cliente) {
                let fa = new Date();
                let fe = new Date(
                  fa.getFullYear(),
                  fa.getMonth(),
                  fa.toLocaleString("es-CO", { day: "numeric" }),
                  fa.getHours(),
                  fa.getMinutes(),
                  fa.getMilliseconds()
                );
                axios
                  .put(
                    Constants.URL_BASE +
                    Constants.WSACTUALIZARCATORCE +
                    cliente.id,
                    {
                      fechaMayorEdad: fe.toISOString(),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      },
                    }
                  )
                  .then((response) => {
                    cliente.fechaMayor14 = fe.toISOString();
                    props.ActualizarUsuario(cliente);
                    document
                      .querySelector(".modal-sucursal")
                      .classList.remove("show-modal");
                    callBack(true);
                  })
                  .catch((err) => {
                    callBack(false);
                  });
              }
            },
            fnCancel: function () {
              // props.history.push('/');
              callBack(false);
            },
          });
        } else {
          callBack(true);
        }
      } else {
        if (!Auth.getToken()) {
          setAlerta({
            tipo: "info",
            titulo: "Inicio de sesión.",
            mensaje:
              "Debes ser mayor de edad para comprar este producto, por favor inicia sesión o regístrate y acepta los términos y condiciones para continuar.",
            btnOk: "Ingresar o Registrarse",
            btnCancel: "Cancelar",
            fnOk: function () {
              props.history.push("/login");
            },
            fnCancel: function () {
              // props.history.push('/');
              callBack(false);
            },
          });
        }
      }
    } else {
      callBack(true);
    }
  };

  const handelItem = (item) => {

    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'productClick',
    //     ecommerce: {
    //       click: {
    //         actionField: {
    //           list: 'Search Results'
    //         },
    //         products: [{
    //           name: item.nombre,
    //           id: item.id,
    //           price: item.precio,
    //           category: item.categoria
    //         }]
    //       }
    //     }
    //   }
    // });

    var id = item.id;
    var name = item.nombre;
    var res =
      name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, "-") +
      "-" +
      id;
    props.history.push("/producto/" + res);
  };

  const handleSinInventario = (inventario) => {
    props.setAlerta({
      tipo: "info",
      titulo: "Sin Inventario.",
      mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + inventario + " unidades",
      btnOk: "Aceptar",
      fnOk: function () { },
      fnCancel: null,
    });
  }

  // AUMENTA LA CANTTIDAD
  const handleMas = (item) => {
    console.log(item);
    let cantidad = 0
    ReactPixel.track('ViewContent', {
      content_name: props.producto.nombre,
      content_category: 'Apparel & Accessories > Shoes',
      content_ids: ['1234'],
      content_type: 'product',
      value: props.producto.precio,
      currency: 'COP',
    })
    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) {

      if (Auth.getToken()) {
        /* firebase
          .analytics()
          .logEvent("agregar_carrito", item.codigoSku + "-" + item.nombre); */
        setCantidad(1)
        var carrito = props.carrito || {};
        if (carrito[item.codigoSku]) {
          //if (carrito[item.codigoSku]["cantidad"]){
          if (carrito[item.codigoSku]["cantidad"] < item.inventario) {
            carrito[item.codigoSku]["cantidad"] += 1;
            cantidad = carrito[item.codigoSku]["cantidad"]
            setCantidad(cantidad)
          } else {
            handleSinInventario(item.inventario)
          }

          /* }else {
            props.setAlerta({
              tipo: "error",
              titulo: "Error.",
              mensaje: "Lo sentimos, la cantidad máxima por producto es 99",
              btnOk: "Aceptar",
              fnOk: function () {},
              fnCancel: null,
            });
          } */
        } else {
          if (item.inventario > 0) {
            carrito[item.codigoSku] = {
              id: item.id,
              nombre: item.nombre,
              precio: parseFloat(item.precio),
              iva: item.iva,
              ico: parseInt(item.ico),
              cantidad: 1,
              inventario: item.inventario,
              descuento: parseInt(item.descuento),
              urlImagen : item.imagen == null || item.imagen.length < 2 ? item.codigoSku : item.imagen
            };
          } else {
            handleSinInventario(item.inventario)
          }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: 'addToCart',
        //     ecommerce: [
        //       {
        //         currencyCode: 'COP',
        //         add: {
        //           products: [
        //             {
        //               'name': item.nombre,
        //               'id': item.codigoSku,
        //               'price': item.precio,
        //               'category': item.categoria,
        //               'quantity': 1,
        //             }
        //           ]
        //         }

        //       }
        //     ]
        //   }
        // });
      } else {
        props.setAlerta({
          tipo: "info",
          titulo: "Inicia sesión",
          mensaje: "Si deseas agregar este producto a tu pedido, por favor inicia sesión.",
          btnOk: "Iniciar sesión",
          btnCancel: "Cancelar",
          fnOk: function () {
            props.history.push("/login")
          },
          fnCancel: function () { }
        })
      }
    } else {
      props.ActualizarCarrito({});
    }
  };

  const handleMenos = (item) => {
    let cantidad = 0
    var carrito = props.carrito || {};
    if (carrito[item.codigoSku]) {
      if (carrito[item.codigoSku]["cantidad"] == 1) {
        delete carrito[item.codigoSku];
        setCantidad(0)
      } else {
        carrito[item.codigoSku]["cantidad"] -= 1;
        cantidad = carrito[item.codigoSku]["cantidad"]
        console.log(carrito[item.codigoSku]["cantidad"]);
        setCantidad(cantidad)
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'removeFromCart',
    //     ecommerce: {
    //       remove: {
    //         products: [{
    //           'name': item.nombre,
    //           'id': item.id,
    //           'price': item.precio,
    //           'category': item.categoria,
    //           'quantity': 1
    //         }]
    //       }
    //     }
    //   }
    // });
  };

  const handleChangeCantidad = (item, valor) => {
    var carrito = props.carrito || {};
    if (Auth.getToken()) {

      setCantidad(valor)
      if (parseInt(valor) > item.inventario) {
        carrito[item.codigoSku]["cantidad"] = 0;
        delete carrito[item.codigoSku];
        setCantidad(0)
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        props.setAlerta({
          tipo: "info",
          titulo: "Sin Inventario.",
          mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + item.inventario + " unidades",
          btnOk: "Aceptar",
          fnOk: function () { },
          fnCancel: null,
        });

      } else {

        if (valor <= 0) {
          delete carrito[item.codigoSku];
        } else {
          if (carrito[item.codigoSku]) {
            if (valor) {
              if (parseInt(valor) <= item.inventario) {
                carrito[item.codigoSku]["cantidad"] = parseInt(valor);

              } else {
                props.setAlerta({
                  tipo: "info",
                  titulo: "Sin Inventario.",
                  mensaje: "Lo sentimos, la cantidad disponible para este producto es de " + item.inventario + " unidades",
                  btnOk: "Aceptar",
                  fnOk: function () { },
                  fnCancel: null,
                });
              }
            } else {
              props.setAlerta({
                tipo: "error",
                titulo: "Error.",
                mensaje: "Lo sentimos, la cantidad máxima por producto es 99",
                btnOk: "Aceptar",
                fnOk: function () { },
                fnCancel: null,
              });
            }
          } else {
            carrito[item.codigoSku] = {
              id: item.id,
              nombre: item.nombre,
              precio: item.precio,
              cantidad: parseInt(valor),
              iva: item.iva,
              ico: item.ico,
              inventario: item.inventario,
              descuento: parseInt(item.descuento),
              urlImagen : item.imagen == null || item.imagen.length < 2 ? item.codigoSku : item.imagen
            };

            // const tagManagerArgs = {
            //   gtmId: process.env.REACT_APP_KEY_GOOGLE_TAG
            // }

            // TagManager.initialize(tagManagerArgs);

            // TagManager.dataLayer({
            //   dataLayer: {
            //     event: 'addToCart',
            //     ecommerce: [
            //       {
            //         currencyCode: 'COP',
            //         add: {
            //           products: [
            //             {
            //               'name': item.nombre,
            //               'id': item.codigoSku,
            //               'price': item.precio,
            //               'category': item.categoria,
            //               'quantity': parseInt(valor),
            //             }
            //           ]
            //         }

            //       }
            //     ]
            //   }
            // });
          }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
      }
    } else {
      props.setAlerta({
        tipo: "info",
        titulo: "Inicia sesión",
        mensaje: "Si deseas agregar este producto a tu pedido, por favor inicia sesión.",
        btnOk: "Iniciar sesión",
        btnCancel: "Cancelar",
        fnOk: function () {
          props.history.push("/login")
        },
        fnCancel: function () { }
      })
    }
  };

  return (
    <React.Fragment>

      <Helmet>
        <script type="application/ld+json">
          {
            `
  "@context":"https://schema.org",
  "@type":"Product",
  "productID":${props.producto.id},
  "name":${props.producto.nombre.toLowerCase()},
  "description":${props.producto.descripcion},
  "url":"https://example.org/facebook",
  "image":${Constants.URL_BASE +
            "/image/" +
            props.producto.codigoSku.trim() +
            ".png"},
  "brand":"facebook",
  "offers": [
    {
      "@type": "Offer",
      "price": ${props.producto.precio},
      "priceCurrency": "COP",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock"
    }
  ],
  "additionalProperty": [{
    "@type": "PropertyValue",
    "propertyID": "item_group_id",
    "value": "fb_tshirts"
  }]
  `
          }
        </script>
      </Helmet>
      <div className="card" key={props.key}>
        {props.producto.descuento > 0 && (
          // <div  className="card-info-vi">
          //     { "-" + props.producto.descuento  + "%"}
          // </div>
          <>
            {empleado != 1 ? (
              <div className="card-info-vi">
                <BsSunFill color="red" size="3rem" />
                <div class="centrado">
                  {"-" + props.producto.descuento + "%"}
                </div>
              </div>
            ) : null}
          </>
        )}
        <div className="card-content">
          <div className="card-img" onClick={() => handelItem(props.producto)}>
            {props.producto.imagen == null || props.producto.imagen.length == 0  ? (
              <img
                style={props.producto.agotado == 1 ? { opacity: 0.3 } : {}}
                className="img-product"
                src={
                  Constants.URL_BASE +
                  "/image/" +
                  props.producto.codigoSku.trim() +
                  ".png"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = noimage;
                }}
              ></img>
            ) : (
              <img
                style={props.producto.agotado == 1 ? { opacity: 0.3 } : {}}
                className="img-product"
                src={
                  Constants.URL_BASE +
                  "/image/" +
                  props.producto.imagen.trim() +
                  ".png"
                }
              ></img>
            )}

          </div>
        </div>
        <p className="nombre">{props.producto.nombre}</p>
        {props.usuario.accesoEmpleado == 1 ? null : (
          <>
            {props.producto.descuento > 0 && (<p className="line-through">
              {" $" +
                new Intl.NumberFormat("en", {
                  numberingSystem: "latn",
                  style: "decimal",
                  currency: "COP",
                }).format(Math.round((props.producto.precio * 100) / (100 - props.producto.descuento)))}
            </p>
            )}
          </>
        )}
        {cantidad > 0 ? (
          <div>
            <p className="precio-Total" style={{ color: "#263B93" }}>Total</p>

            <p className="precio">

              {" $" +
                new Intl.NumberFormat("en", {
                  numberingSystem: "latn",
                  style: "decimal",
                  currency: "COP",
                }).format(props.producto.precio)}
              &nbsp; &nbsp;&nbsp;


              <span style={{ color: "#263B93" }}>
                {" $" +
                  new Intl.NumberFormat("en", {
                    numberingSystem: "latn",
                    style: "decimal",
                    currency: "COP",
                  }).format(props.producto.precio * cantidad)}
              </span>
            </p>
          </div>
        ) : (

          <p className="precio">
            {" $" +
              new Intl.NumberFormat("en", {
                numberingSystem: "latn",
                style: "decimal",
                currency: "COP",
              }).format(props.producto.precio)}
          </p>

        )}

        {props.producto.agotado == 0 ? (
          <div className="action">
            {props.carrito[props.producto.codigoSku] ? (
              <div
                className="controls"
                style={{ border: "1px solid " + props.color }}
              >
                <button
                  role="button"
                  onClick={() => handleMenos(props.producto)}
                >
                  {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                  <svg
                    height="15pt"
                    width="15pt"
                    viewBox="0 -192 469.33333 469"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"
                      fill={props.color ? props.color : "#2098de"}
                    />
                  </svg>
                </button>
                <input
                  type="number"
                  minLength="0"
                  maxLength="99"
                  onChange={(e) =>
                    handleChangeCantidad(props.producto, e.target.value)
                  }
                  value={props.carrito[props.producto.codigoSku].cantidad}
                //  value={props.carrito[props.producto.codigoSku].cantidad == undefined ? 0 :1}
                />
                <button role="button" onClick={() => handleMas(props.producto)}>
                  {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                  <svg
                    height="15pt"
                    width="15pt"
                    viewBox="0 0 469.33333 469.33333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"
                      fill={props.color ? props.color : "#2098de"}
                    />
                  </svg>
                </button>
              </div>

            ) : (
              <div
                className="controls"
                style={{ border: "1px solid " + props.color }}
              >
                <button
                  role="button"
                  onClick={() => handleMenos(props.producto)}
                >
                  {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                  <svg
                    height="15pt"
                    width="15pt"
                    viewBox="0 -192 469.33333 469"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0"
                      fill={props.color ? props.color : "#2098de"}
                    />
                  </svg>
                </button>
                <input
                  type="number"
                  minLength="0"
                  maxLength="99"
                  onChange={(e) =>
                    handleChangeCantidad(props.producto, e.target.value)
                  }
                  // value={props.carrito[props.producto.codigoSku].cantidad}
                  value={""}
                />
                <button role="button" onClick={() => handleMas(props.producto)}>
                  {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                  <svg
                    height="15pt"
                    width="15pt"
                    viewBox="0 0 469.33333 469.33333"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0"
                      fill={props.color ? props.color : "#2098de"}
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="agotado">
            <p>Agotado</p>
          </div>
        )}
      </div>
      {alerta ? <AlertModal alerta={alerta} updateAlerta={setAlerta} /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
    carrito: selectActiveCarrito(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { ActualizarCarrito })(CardProducto)
);
