import * as Const from '../conts';
const initialState = { empleadoActivo: "" };

export default (state = initialState, action) => {
    switch (action.type) {
        case Const.SET_EMPLEADO:
            return {
                ...state,
                empleadoActivo: action.payload
            };
        default:
            return state;
    }
};

export const selectActiveEmpleado = state => state.empleadoActivoReducer.empleadoActivo;