import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import logo from "../../assets/img/todo_rico_sf.png";
import logo_III from "../../assets/img/logo_III.png";
import facebook from "../../assets/icon/facebook.svg";
import instagram from "../../assets/icon/instagram.svg";
import youtube from "../../assets/icon/youtube.svg";

const Footer = (props) => {
  const handleRedirect = (item) => {
    props.history.push(item);
  };
  return (
    <footer>
      <div className="main-content">
        <div className="left">
          <div className="logo">
            <img src={logo_III} />
          </div>
          <div className="logo">
            <img src={logo} />
          </div>
        </div>
        <div className="center">
          <ul>
            <li>
              <a
                href="https://emartwebapi.celuwebdev.com/superricasB2C/pdf/POLITICA%20VIAL%20BASC,%20SAGRILAFT%20Y%20ETICA%20EMPRESARIAL%202021%20Versi%C3%B3n%20para%20impr....pdf"
                target="_blank"
              >
                Política de Seguridad
              </a>
            </li>
            <li>
              <a
                href="https://emartwebapi.celuwebdev.com/superricasB2C/pdf/POLITICA%20_DE_CALIDAD_2017.pdf"
                target="_blank"
              >
                Política de calidad
              </a>
            </li>

            <li>
              <a
                href="https://www.superricas.com/themes/sitio/resources/assets/images/pdf/aviso-de-privacidad-comestibles-ricos-2017.pdf"
                target="_blank"
              >
                Aviso de privacidad Comestibles Ricos S.A.
              </a>
            </li>
            <li>
              <a
                href="https://emartwebapi.celuwebdev.com/superricasB2C/pdf/POL%C3%8DTICA%20DE%20TRATAMIENTO%20PROTECCI%C3%93N%20DE%20DATOS%202022%20V%2002.pdf"
                target="_blank"
              >
                Política de tratamiento protección de datos
              </a>
            </li>
            <li>
              <a
                href="https://www.superricas.com/themes/sitio/resources/assets/images/pdf/politica-de-tratamiento-de-datos.pdf"
                target="_blank"
              >
                Política de Cookies
              </a>
            </li>
          </ul>
        </div>
        <div className="center2">
          <ul className="sc">
            <li>
              <a
                style={{ fontSize: "1rem", marginTop: 0, fontWeight: "bold" }}
                href="https://emartwebapi.celuwebdev.com/superricasB2C/pdf/POLITICA_VIAL_BASC_Y_SAGRLAFT_2019_PARA_IMPRESION.pdf"
                target="_blank"
              >
                Servicio al cliente
              </a>
            </li>
            <li>
              <a href="#">Bogotá: 601 2670410</a>
            </li>
            <li>
              <a href="#">Línea gratuita nacional: 018000423770</a>
            </li>
            <li>
              <a href="#">Lunes a Viernes de 8 am a 6 pm</a>
            </li>
            <li>
              <a href="#">Sábados de 8 am a 12 pm</a>
            </li>
          </ul>
        </div>
        <div className="right">
          <div>
            <li>
              <a
                style={{ color: "white" }}
                href="https://www.superricas.com/"
                target="_blank"
              >
                <b>www.superricas.com</b>
              </a>
            </li>
            <li>
              <a
                style={{ color: "white" }}
                href="https://www.superricas.com/contactenos"
                target="_blank"
              >
                Contáctenos
              </a>
            </li>
          </div>
          <div className="icon-link">
            <a
              href="https://www.facebook.com/superricasoficial/"
              target="_blank"
              className="icon"
            >
              <img
                src={facebook}
                alt="icon facebook"
                width="15px"
                height="15px"
              ></img>
            </a>
            <div className="links">
              <a
                href="https://www.facebook.com/superricasoficial"
                target="_blank"
              >
                @superricasoficial
              </a>
              <a
                href="https://www.facebook.com/TodoRicoOficial"
                target="_blank"
              >
                @Todoricooficial
              </a>
            </div>
          </div>
          <div className="icon-link">
            <a
              href="https://www.facebook.com/superricasoficial/"
              target="_blank"
              className="icon"
            >
              <img
                src={instagram}
                alt="icon instagram"
                width="15px"
                height="15px"
              ></img>
            </a>
            <div className="links">
              <a
                href="https://www.instagram.com/superricas/"
                target="_blank"
                className="link"
              >
                @Superricas
              </a>
              <a
                href="https://www.instagram.com/todorico.oficial/"
                target="_blank"
                className="link"
              >
                @Todorico.oficial
              </a>
            </div>
          </div>
          <div className="icon-link">
            <a
              href="https://www.facebook.com/superricasoficial/"
              target="_blank"
              className="icon"
            >
              <img
                src={youtube}
                alt="icon youtube"
                width="15px"
                height="15px"
              ></img>
            </a>
            <div className="links">
              <a
                href=" https://www.youtube.com/channel/UCX_vITtCaF3yta0Nc7FdgEQ"
                target="_blank"
              >
                SuperRicas
              </a>
              <a
                href="https://www.youtube.com/channel/UCWnjwjjoA-GAWIpClvrssPw"
                target="_blank"
              >
                TodoRico
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
