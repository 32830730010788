import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Navbar from '../navbar/Navbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer';
import Search from '../util/Search';
import axios from 'axios'
import * as Constants from '../../constants/global'

import visibility from '../../assets/icon/visibility.svg';
import SucursalModal from '../modal/SucursalModal';
import noimage from "../../assets/img/logo_III.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Catalogo = (props) => {

    const [search, setSearch] = useState('');
    const [categoria, setCategoria] = useState();
    const [categoriaId, setCategoriaId] = useState();
    const [banner, setBanner] = useState();

    const handleCategoria = item => {
        // setSearch('')
        setCategoria(item);
    }

    const onClickItem = (item) => {
        window.location.href = banner[item].enlace;
    };
    
    const getBannerCategoria = (categoryId) => {
        let url = Constants.URL_BASE + Constants.WSBANNERCATEGORIA + "?categoriaId=" + categoryId
        axios.get(url).then((response)=>{
            setBanner(response.data)
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if(props.match.params.categoria){
            var urlCategoria = props.match.params.categoria;
            var categoriaArray = urlCategoria.split("-");
            var categoryId = categoriaArray[categoriaArray.length - 1];
            setCategoriaId(categoryId);
            getBannerCategoria(categoryId)
        }else{
            setCategoriaId(15);
        }

        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        const q = params.get('q');

        if(q){
            setSearch(q); 
        }
        
    }, [props.match.params.categoria])

    return (
        <React.Fragment>
            <div className="navbar">
                <Navbar item={1} />
                <Search search={search} updateSearch={setSearch}/>                                            
                <Sub_nav/>
                <Categoria categoriaId={categoriaId} handleCategoria={handleCategoria}/>
            </div>

            <div className="layout">
                <div className="inicio">
                    {banner && (
                        <div className="banner">
                            <Carousel
                                showArrows={true}
                                showThumbs={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                showStatus={false}
                                onClickItem={onClickItem}
                                >
                                {banner.map((item, i) => (
                                    <div>
                                        <img
                                            className="img-banner"
                                            src={Constants.URL_BASE + "categoriaBanner/" + item.imagen}
                                            onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = noimage;
                                            }}
                                        ></img>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    )}
                    <Producto 
                        tipo={1} 
                        categoriaId={categoriaId}
                        categoria={categoria} 
                        search={search}/>
                </div>
            </div>
            
           

            <SucursalModal mayorEdad={true} />

            <Footer />
        </React.Fragment>
    )
}

export default withRouter(Catalogo);