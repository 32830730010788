import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';
import DireccionPlaces from '../util/DireccionPlaces';
import AlertModal from '../modal/AlertModal';

import {selectActiveUser} from '../../store/usuario/reducer';

const EditModal = (props) => {

    const [alerta, setAlerta] = useState();
    const [error, setError] = useState("");
    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [barrios, setBarrios] = useState([]);
    const [departamento, setDepartamento] = useState("NONE");
    const [municipio, setMunicipio] = useState("NONE");
    const [direccion, setDireccion] = useState(); 
    const [barrio, setBarrio] = useState();
    const [complemento, setComplemento] = useState();
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const [identificador, setIdentificador] = useState(); 

    const handleClose = (state) => {
        props.callback(state)
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    const handleUpdate = () =>{
        handleClose(true)
    }

    const handleUpdateDir = () =>{
        var err = error || {};
        if(departamento == "NONE"){
            err['Departamento'] = "El departamento es obligatorio.";
            setError(err);
        }

        if(municipio == "NONE") {
            err['Municipio'] = "El municipio es obligatorio.";
            setError(err);
        }

        if(!direccion) {
            err['Direccion'] = "la direccion es obligatoria.";
            setError(err);
        }

        if(!barrio) {
            err['Barrio'] = "El barrio es requerido.";
            setError(err);
        }
        
        if(Object.keys(err).length == 0) {
            var depto = JSON.parse(departamento);
            var muni = JSON.parse(municipio);
            
            let nuevaDir = {
                'departamentoId': Number(depto.id),
                'municipioId': Number(muni.id),
                'barrioId':0,
                'direccion': direccion,
                'referencia': barrio + " - " + complemento,
                'latitud': latitud,
                'longitud': longitud,
                'direccionId': props.item.id
            }

            let url = Constants.URL_BASE + Constants.WSACTUALIZARDIRECCION + props.usuario.id

            axios.put(url, nuevaDir, {
                headers:{                
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }).then((response)=>{
                Auth.setUbicacion({
                    departamentoId: depto.id,
                    departamento: depto.name,
                    municipioId: muni.id,
                    municipio: muni.name,
                })
                window.location.reload(true)   
                handleUpdateClient()
                handleClose(true)  
            }).catch((err)=>{
                if(err.response){
                    setError(err.response.data.errors)
                }            
            })        
        }
    }

    const handleSaveDir = () =>{
        var cliente = props.usuario;
        var err = {};
        
        if(!identificador){
            err['Identificador'] = "Por favor ingrese una identificación.";
            setError(err);
        }

        if(departamento == "NONE"){
            err['Departamento'] = "El departamento es obligatorio.";
            setError(err);
        }

        if(municipio == "NONE") {
            err['Municipio'] = "El municipio es obligatorio.";
            setError(err);
        }

        if(!direccion) {
            err['Direccion'] = "la direccion es obligatoria.";
            setError(err);
        }

        if(!barrio) {
            err['Barrio'] = "El barrio es requerido.";
            setError(err);
        }
        
        if(Object.keys(err).length == 0) {
            var depto = JSON.parse(departamento);
            var muni = JSON.parse(municipio);
            
            let nuevaDir = {
                "clienteId": cliente.id,
                'departamentoId': Number(depto.id),
                'municipioId': Number(muni.id),
                'barrioId':0,
                'direccion': direccion,
                'referencia': barrio + " - " + (complemento ? complemento : ""),
                'latitud': latitud,
                'longitud': longitud,
                'identificador': identificador
            }

            let url = Constants.URL_BASE + 'Cliente/Direccion';
            axios.post(url, nuevaDir, {
                headers:{                
                    Authorization: `Bearer ${Auth.getToken()}`
                }
            }).then((response)=>{
                Auth.setUbicacion({
                    direccion: JSON.stringify(nuevaDir),
                    departamentoId: depto.id,
                    departamento: depto.name,
                    municipioId: muni.id,
                    municipio: muni.name,
                })
                window.location.reload(true)   
                // handleUpdateClient()
                handleClose(true);
            }).catch((err)=>{
                console.log(err.response.data.errors)
                // if(err.response){
                //     setError(err.response.data.errors)
                // }            
            })        
        }
    }

    const handleUpdateClient = () => {
        var depto = JSON.parse(departamento);
        var muni = JSON.parse(municipio);
        var cliente = props.usuario;
        cliente.direcciones[0].departamentoId=Number(depto.id)
        cliente.direcciones[0].departamento = depto.name;
        cliente.direcciones[0].municipioId = Number(muni.id)
        cliente.direcciones[0].municipio = muni.name;
        cliente.direcciones[0].direccion = direccion ? direccion : ''
        cliente.direcciones[0].referencia = barrio + " - " + complemento;
        handleClose()
    }

    const handleChangeDep = (item) => {
        var depto = JSON.parse(item);
        var ubicacion = Auth.getUbicacion();
        var carrito = Auth.getCarrito();

        if(Object.keys(ubicacion).length > 0 && Object.keys(carrito).length > 0){
            if(ubicacion.departamentoId != depto.id){
                setAlerta({
                    tipo: "warning",
                    titulo: "Alerta.",
                    mensaje: "Ha cambiado la ubicación registrada con anterioridad. Se evaluará la cobertura de su pedido.",
                    btnOk: "Aceptar",
                    fnOk: function(){},
                    fnCancel: null
                })    
            }
        }

        if(item != "NONE"){
            setDepartamento(item);
            setMunicipio("NONE");
            getMunicipios(depto.id);
            setDireccion("");
            setBarrio("");
        }else{
            setDepartamento(item);
            setMunicipios([]);
            setMunicipio("NONE");
            setDireccion("");
            setBarrio("");
        }
    }


    const handleChangeMun = (item) =>{
        if(item != "NONE"){
            setMunicipio(item);
            setDireccion("");
            setBarrio("");
            getBarrios(JSON.parse(item).id);
        }else{
            getBarrios([]);
            setMunicipio(item);
            setDireccion("");
            setBarrio("");
        }
    }

    const getDepartamentos = () =>{
        let url = Constants.URL_BASE + Constants.WSDEPARTAMENTOS + "?co=1" 
        axios.get(url).then(res=>{
            let data = res.data || [];
            setDepartamentos(data);
            let direccion = props.usuario.direcciones[0];
            data.map((item,i)=>{
                if(direccion.departamentoId == item.id){
                    setDepartamento(JSON.stringify(item));
                    getMunicipios(item.id);
                }
            });
        })
    }

    const getMunicipios = (departamentoId) =>{
        let url = Constants.URL_BASE + Constants.WSMUNICIPIO + departamentoId + "?co=1"
        axios.get(url).then(res=>{
            let data = res.data || [];
            setMunicipios(data);
            let direccion = props.usuario.direcciones[0];
            data.map((item,i)=>{
                if(direccion.municipioId == item.id){
                    setMunicipio(JSON.stringify(item));
                    getBarrios(item.id)
                }
            });
        })
    }

    const getBarrios = (municpioId) =>{
        let url = Constants.URL_BASE + Constants.WSBARRIO +  municpioId;
        axios.get(url).then(res=>{
            let data = res.data || [];
            setBarrios(data);
        })
    }

    useEffect(() => {
        console.log(props);
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");     
        getDepartamentos();
        if(props.editar == 2){
            let direccion = props.usuario.direcciones.find(d => d.id === props.item.id);
            setLatitud(direccion.latitud);
            setLongitud(direccion.longitud);
            setDireccion(direccion.direccion);
            var ref = direccion.referencia.split("-");
            if(ref.length > 0 && ref.length == 2){
                const inpBarrio = ref[0] ? ref[0].trim() : "";
                const inpReferencia = ref[1] ? ref[1].trim() : "";
                setBarrio(inpBarrio || "");
                setComplemento(inpReferencia || "");
            } else if(ref.length > 0 && ref.length == 3){
                const inpBarrio = `${ref[0]} - ${ref[1]}`
                const inpReferencia = ref[2];
                setBarrio(inpBarrio || "");
                setComplemento(inpReferencia || "");
            }
        }
    }, [props.usuario])

    return (
        <React.Fragment>
            <div className="update">
                <div className="update-content">
                    <div className="update-form" id="update-form">
                        <form>
                            <div className="form-control">
                                <div className="modal modal-sucursal">
                                    <div className="modal-content edit-content">       
                                        <div className="title">
                                            {props.editar == 1 ? (
                                                <p>Datos personales</p>
                                            ) : (
                                                <p>Mi dirección</p>
                                            )}
                                            
                                            <span className="close-button" onClick={() => handleClose(false)}>
                                                <img 
                                                    src={close} 
                                                    width="15" 
                                                    height="15" 
                                                    alt="icon close"/>
                                            </span>  
                                        </div>
                                        <div className="content">
                                            
                                            {props.editar == 1 && (
                                                <React.Fragment>
                                                    <form >
                                                        <div className="form-control">
                                                            <label>Nombre:</label>
                                                            <input type="text" defaultValue={props.usuario.nombre} placeholder="Nombre completo" onChange={e => props.usuario.nombre = e.target.value }></input>                                                        
                                                            { error.Nombres ? (
                                                                <span className="error">
                                                                    <b>{error.Nombres}</b>
                                                                </span>
                                                            ) : null }
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Apellidos:</label>
                                                            <input type="text" placeholder="apellidos" defaultValue={props.usuario.apellido} onChange={e => props.usuario.apellido = e.target.value}></input>
                                                            { error.Apellidos ? (
                                                                <span className="error">
                                                                    <b>{error.Apellidos}</b>
                                                                </span>
                                                            ) : null }
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Celular:</label>
                                                            <input type="number" placeholder="Celular" defaultValue={props.usuario.telefono} onChange={e => props.usuario.telefono = (e.target.value)}></input>                                                        
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Correo:</label>
                                                            <input type="email" defaultValue={props.usuario.email} placeholder="Dirección de correo electronico" onChange={e => props.usuario.email = (e.target.value)}></input>                                                        
                                                            { error.Email ? (
                                                                <span className="error">
                                                                    <b>{error.Email}</b>
                                                                </span>
                                                            ) : null }
                                                        </div>
                                                        <div className="form-control">
                                                            <label>Contraseña:</label>
                                                            <input type="password" defaultValue={props.usuario.clave} placeholder="Contraseña" onChange={e => props.usuario.clave = (e.target.value)}></input>                                                        
                                                            { error.Clave ? (
                                                                <span className="error">
                                                                    <b>{error.Clave}</b>
                                                                </span>
                                                            ) : null }
                                                        </div>
                                                        
                                                        { error['$.habeasData'] ? (
                                                            <span className="error">
                                                                <b>Por favor acepte terminos y condiciones</b>
                                                            </span>
                                                        ) : null }
                                                        
                                                       
                                                        <button type="button" className="btn-large" onClick={() => handleUpdate()}>Actualizar</button>
                                                        <button type="button" className="btn-large outline" onClick={() => handleClose(false)}>Cancelar</button>
                                                    </form>
                                                </React.Fragment>
                                            )} 

                                            {props.editar == 2 && (
                                                <form>
                                                    <div className="form-control">
                                                        <label>Departamento:</label>
                                                        <select 
                                                            className={error.Departamento ? "input-error" : null}
                                                            name="departamentos" 
                                                            value={departamento} 
                                                            onChange={e => handleChangeDep(e.target.value)}>
                                                            <option selected="true" value="NONE">Seleccione departamento</option>
                                                            {departamentos.map((item,i)=>(
                                                                <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                            ))}
                                                        </select> 
                                                        { error.Departamento ? (
                                                            <span className="error">
                                                                <b>{error.Departamento}</b>
                                                            </span>
                                                        ) : null }
                                                    </div>
                                                    {municipios ? (
                                                        <div className="form-control"> 
                                                            <label>Municipio:</label>
                                                            <select 
                                                                className={error.Municipio ? "input-error" : null}
                                                                name="municipios" 
                                                                value={municipio} 
                                                                onChange={e => handleChangeMun(e.target.value)}>
                                                                <option selected="true" value="NONE">Seleccione un municipio</option>
                                                                {municipios.map((item,i)=>(
                                                                    <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                                ))}
                                                            </select> 
                                                            { error.Municipio ? (
                                                                <span className="error">
                                                                    <b>{error.Municipio}</b>
                                                                </span>
                                                            ) : null }
                                                        </div>
                                                    ): 
                                                        <div className="form-control">
                                                            <label>Municipio:</label>
                                                            <select name="municipios" >  
                                                                <option>Seleccione un municipio</option>                                         
                                                            </select> 
                                                        </div>
                                                    }  
                                                    { departamento != "NONE" && municipio != "NONE" ? (
                                                        <DireccionPlaces 
                                                            barrios={barrios}
                                                            departamento={departamento}
                                                            municipio={municipio}
                                                            direccion={direccion}
                                                            barrio={barrio}
                                                            complemento={complemento}
                                                            latitud={latitud}
                                                            longitud={longitud}
                                                            updateDireccion={setDireccion}
                                                            updateBarrio={setBarrio}
                                                            updateComplemento={setComplemento}
                                                            updateLatitud={setLatitud}
                                                            updateLongitud={setLongitud}
                                                            error={error}/>     
                                                    ) : null } 
                                                                                                        
                                                    <button type="button" className="btn-large" onClick={e => handleUpdateDir()} >Actualizar</button>
                                                    <button type="button" className="btn-large outline" onClick={() => handleClose(false)}>Cancelar</button>
                                                </form>
                                            )}

                                            {props.editar == 3 && (
                                                <>
                                                   <form>
                                                    <div className="form-control">
                                                        <label>Idenfica tu dirección:</label>
                                                        <input type="text"  placeholder="Ejemplo: Casa, Oficina." onChange={e => setIdentificador(e.target.value) }></input>                                                        
                                                        { error.Identificador ? (
                                                            <span className="error">
                                                                <b>{error.Identificador}</b>
                                                            </span>
                                                        ) : null }
                                                    </div>
                                                    <div className="form-control">
                                                        <label>Departamento:</label>
                                                        <select 
                                                            className={error.Departamento ? "input-error" : null}
                                                            name="departamentos" 
                                                            value={departamento} 
                                                            onChange={e => handleChangeDep(e.target.value)}>
                                                            <option selected="true" value="NONE">Seleccione departamento</option>
                                                            {departamentos.map((item,i)=>(
                                                                <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                            ))}
                                                        </select> 
                                                        { error.Departamento ? (
                                                            <span className="error">
                                                                <b>{error.Departamento}</b>
                                                            </span>
                                                        ) : null }
                                                    </div>
                                                    {municipios ? (
                                                        <div className="form-control"> 
                                                            <label>Municipio:</label>
                                                            <select 
                                                                className={error.Municipio ? "input-error" : null}
                                                                name="municipios" 
                                                                value={municipio} 
                                                                onChange={e => handleChangeMun(e.target.value)}>
                                                                <option selected="true" value="NONE">Seleccione un municipio</option>
                                                                {municipios.map((item,i)=>(
                                                                    <option key={i} value={JSON.stringify(item)}> {item.name} </option>
                                                                ))}
                                                            </select> 
                                                            { error.Municipio ? (
                                                                <span className="error">
                                                                    <b>{error.Municipio}</b>
                                                                </span>
                                                            ) : null }
                                                        </div>
                                                    ): 
                                                        <div className="form-control">
                                                            <label>Municipio:</label>
                                                            <select name="municipios" >  
                                                                <option>Seleccione un municipio</option>                                         
                                                            </select> 
                                                        </div>
                                                    }  
                                                    { departamento != "NONE" && municipio != "NONE" ? (
                                                        <DireccionPlaces 
                                                            barrios={barrios}
                                                            departamento={departamento}
                                                            municipio={municipio}
                                                            direccion={direccion}
                                                            barrio={barrio}
                                                            complemento={complemento}
                                                            latitud={latitud}
                                                            longitud={longitud}
                                                            updateDireccion={setDireccion}
                                                            updateBarrio={setBarrio}
                                                            updateComplemento={setComplemento}
                                                            updateLatitud={setLatitud}
                                                            updateLongitud={setLongitud}
                                                            error={error}/>     
                                                    ) : null } 
                                                                                                        
                                                    <button type="button" className="btn-large" onClick={e => handleSaveDir()} >Guardar</button>
                                                    <button type="button" className="btn-large outline" onClick={() => handleClose(false)}>Cancelar</button>
                                                </form>      
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div> 
                            </div>        
                        </form>  
                    </div>
                </div>
            </div>
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta}/>) : null}
        </React.Fragment>
    )
}


const mapStateToprops = (state) => {
    return {
      usuario: selectActiveUser(state)
    }
}

export default withRouter(connect(mapStateToprops) (EditModal));