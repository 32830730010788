import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sub_nav from "../navbar/Sub_nav";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import * as Constants from "../../constants/global";
import axios from "axios";
import detail from "../../assets/icon/detail.svg";
import DetalleModal from "../modal/DetalleModal";
import Progress from "../util/Progress";
import perfil from "../../assets/icon/perfil.svg";
import moment from "moment";
import Auth from "../../helpers/Auth";

// REDUX
import { selectActiveUser } from "../../store/usuario/reducer";

const Pedidos = (props) => {
  moment.lang("es", {
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
      "_"
    ),
    monthsShort: "Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.".split(
      "_"
    ),
    weekdays: "Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado".split("_"),
    weekdaysShort: "Dom._Lun._Mar._Mier._Jue._Vier._Sab.".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sa".split("_"),
  });

  const states = [
    {
      number: "1",
      state: "Pendiente",
      bgColor: "#B03A2E",
    },
    {
      number: "2",
      state: "En despacho",
      bgColor: "#D4AC0D",
    },
    {
      number: "3",
      state: "Entregado",
      bgColor: "#186A3B",
    },
  ];

  const [loader, setLoader] = useState(false);
  const [carrito, setCarrito] = useState({});
  const [cantidad, setCantidad] = useState(0);
  const [cliente, setCliente] = useState();
  const [pedidos, setPedidos] = useState();
  const [pedido, setPedido] = useState();

  const getCantidad = () => {
    var cant = 0;
    let cart = JSON.parse(localStorage.getItem("carrito")) || {};
    Object.keys(cart).map((item, i) => {
      cant += cart[item].cantidad;
    });
    setCantidad(cant);
  };

  const getPedidos = () => {
    setLoader(true);
    let client = props.usuario;
    let url =
      Constants.URL_BASE + Constants.WSPEDIDOSCLIENTE + "/" + client.doc;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        response.data.map((item, i) => {
          states.forEach((state) => {
            if (state.number == item.estado) {
              response.data[i].estado = state.state;
              response.data[i].bgColor = state.bgColor;
            }
          });
        });
        setTimeout(() => {
          setLoader(false);
        }, 2500);
        setPedidos(response.data);
      })
      .catch((err) => {});
  };

  const getResponse = (response) => {
    if (response) {
      setPedido(null);
    }
  };

  useEffect(() => {
    setCarrito(JSON.parse(localStorage.getItem("carrito")) || {});
    setCliente(props.usuario);
    getCantidad();
    getPedidos();
  }, [props.usuario]);

  return (
    <React.Fragment>
      <div className="navbar navcarrito">
        <Navbar item={3} />
        <Sub_nav cantidad={cantidad} />
      </div>
      <div className="layout">
        <div className="historial">
          {pedidos ? (
            <React.Fragment>
              {loader ? (
                <Progress color={"#0194C5"}></Progress>
              ) : (
                <React.Fragment>
                  <a onClick={(e) => props.history.push("/")}>
                    Seguir comprando
                  </a>

                  <div className="table-title">
                    <h3>Lista de pedidos</h3>
                  </div>

                  <div className="table-responsive">
                    <p className="table-mensaje">
                      Si necesitas informacion del estado de tu pedido,
                      comunícate a la línea de servicio al cliente 
                      Bogotá: (601) 2670410 - Línea gratuita nacional: 018000423770
                      servicioalclientenacional@superricas.com
                    </p>
                    <table className="mytabla mytabla-responsive">
                      <thead>
                        <tr>
                          <th className="text-left">Detalle</th>
                          <th className="text-left">Número de orden</th>
                          <th className="text-left">Fecha</th>
                          <th className="text-left">Dirección</th>
                          <th className="text-left">Observación</th>
                          <th className="text-left">Estado</th>
                          <th className="text-left">Pedido</th>
                          <th className="text-left">Tipo de pago</th>
                          {/* <th className="text-left">Envío</th> */}
                          <th className="text-left">Total</th>
                        </tr>
                      </thead>
                      <tbody className="table-hover">
                        {pedidos.map((item, i) => (
                          <tr key={i}>
                            <td
                              className="text-left"
                              style={{ textAlign: "center" }}
                            >
                              <a onClick={(e) => setPedido(item)}>
                                <img
                                  src={detail}
                                  alt="icon delete"
                                  width="30px"
                                  height="30px"
                                ></img>
                              </a>
                            </td>
                            <td className="text-left">{item.numeroDoc}</td>
                            <td className="text-left">
                              {moment(item.fecha).format(
                                "dddd, MMMM D, YYYY",
                                "LLLL"
                              )}
                            </td>
                            <td className="text-left">
                              {item.direccion +
                                " " +
                                (item.barrio ? item.barrio + " " : "") +
                                (item.referencia
                                  ? ", " + item.referencia + ", "
                                  : "") +
                                item.municipio +
                                "/" +
                                item.departamento}
                            </td>
                            <td className="text-left">{item.observacion}</td>
                            <td
                              className="text-left"
                              style={{ color: item.bgColor, textAlign:"center" }}
                            >
                              {item.estadoNombre}
                            </td>
                            <td
                              className="text-left"
                              style={{ color: item.bgColor, textAlign:"center" }}
                            >
                              {item.acceso == "1" ? "EMPLEADO" : "USUARIO"}
                            </td>
                            <td className="text-left" style={{ textAlign:"center" }}>
                              {item.tipoPago == "CT"
                                ? "CONTRA ENTREGA"
                                : item.tipoPago == "NOMINA" ? "NOMINA" : "PAGO EN LINEA"}
                            </td>
                            {/* <td className="text-left">Gratis</td> */}
                            <td className="text-left">
                              {" $" +
                                new Intl.NumberFormat("en", {
                                  numberingSystem: "latn",
                                  style: "decimal",
                                  currency: "COP",
                                }).format(item.total)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {pedido ? (
                    <DetalleModal
                      pedido={pedido}
                      updatePedidos={getPedidos}
                      usuario={props.usuario}
                      
                      callback={getResponse.bind(this)}
                    />
                  ) : null}
                </React.Fragment>
              )}
            </React.Fragment>
          ) : null}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(Pedidos));
