import React from "react"
import logo from '../../assets/img/logo_II.png'



const NotFoundPage = () => {
  
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <br></br>
            <img src={logo} alt="logo" width={400} height="auto"/>
            <br></br>
            <div className="location"><h2>Pagina temporalmente fuera de servicio</h2></div>
            </div>
        </div>
    )

  
}

export default NotFoundPage
