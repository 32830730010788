import axios from 'axios';
import { useState } from 'react';

export const KEY_TOKEN = "token";
export const KEY_UBICACION = "ubicacion";
export const KEY_CARRITO = "carrito";
export const KEY_PRODUCTO = "producto";
export const KEY_CATEGORIA = 'categoria';
export const KEY_EMPLEADO = 'ingresoEmpleado'

const Auth = {
    
    signout() {
        localStorage.removeItem(KEY_TOKEN)
        localStorage.removeItem(KEY_EMPLEADO)
        
    },

    setToken(token) {
        return localStorage.setItem(KEY_TOKEN, token);
    },

    getToken() {
        return localStorage.getItem(KEY_TOKEN);
    },

    setUbicacion(ubicacion) {
        return localStorage.setItem(KEY_UBICACION, JSON.stringify(ubicacion));
    },

    getUbicacion() {
        return JSON.parse(localStorage.getItem(KEY_UBICACION)) || {};
    },
    
    setCarrito(carrito) {
        return localStorage.setItem(KEY_CARRITO, JSON.stringify(carrito));
    },

    getCarrito() {
        return JSON.parse(localStorage.getItem(KEY_CARRITO)) || {};
    },

    removeCarrito() {
        localStorage.removeItem(KEY_CARRITO);
    },

    setCategoria(id){
        localStorage.setItem(KEY_CATEGORIA,id)
    },

    getCategoria(){
        return localStorage.getItem(KEY_CATEGORIA)
    },

    headerToken() {
        axios.interceptors.request.use(function(config) {
            const token = localStorage.getItem(KEY_TOKEN);
            if ( token != null ) {
              config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function(err) {
            return Promise.reject(err);
        });
    }
      
}

export default Auth
  