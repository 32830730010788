import { createStore, combineReducers } from 'redux';
import usuarioReducer from './usuario/reducer';
import alertReducer from './alerta/reducer';
import carritoReducer from './carrito/reducer';
import empleadoActivoReducer from './empleado/reducer'

const reducers = combineReducers({
    usuarioReducer,
    carritoReducer,
    alertReducer,
    empleadoActivoReducer
});

const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;