import React, { useState, useEffect } from "react";
const Payu = (props) => {

    const sendPage = () => {
        document.formPayu.submit()
    }

    useEffect(() => {
        sendPage()
    });
    return (
        <React.Fragment>
            <form method="post" name="formPayu" action="https://checkout.payulatam.com/ppp-web-gateway-payu/">
                <input name="merchantId" type="hidden" value="959580" />
                <input name="accountId" type="hidden" value="967300" />
                <input name="description" type="hidden" value="Pedido Super Ricas" />
                <input name="referenceCode" type="hidden" value={props.referencia} />
                <input name="amount" type="hidden" value={props.total} />
                <input name="tax" type="hidden" value="0" />
                <input name="taxReturnBase" type="hidden" value="0" />
                <input name="currency" type="hidden" value="COP" />
                <input name="signature" type="hidden" value={props.encripte} />
                <input name="test" type="hidden" value="1" />
                <input name="buyerEmail" type="hidden" value={props.usuario.email} />
                <input name="responseUrl" type="hidden" value="https://tienda.superricas.com/carrito" />
                <input name="confirmationUrl" type="hidden" value="https://tienda.superricas.com/carrito" />
                <input name="paymentMethods" type="hidden" value="AMEX,CODENSA,DINERS,MASTERCARD,VISA,PSE" />
            </form>
            {/* <form method="post" name="formPayu" action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/">
                <input name="merchantId" type="hidden" value="508029" />
                <input name="accountId" type="hidden" value="512321" />
                <input name="description" type="hidden" value="Pedido Super Ricas" />
                <input name="referenceCode" type="hidden" value={props.referencia} />
                <input name="amount" type="hidden" value={props.total} />
                <input name="tax" type="hidden" value="0" />
                <input name="taxReturnBase" type="hidden" value="0" />
                <input name="currency" type="hidden" value="COP" />
                <input name="signature" type="hidden" value={props.encripte} />
                <input name="test" type="hidden" value="0" />
                <input name="buyerEmail" type="hidden" value={props.usuario.email} />
                <input name="responseUrl" type="hidden" value="https://superricasb2c.web.app/" />
                <input name="confirmationUrl" type="hidden" value="https://superricasb2c.web.app/" />
                <input name="paymentMethods" type="hidden" value="AMEX,BANK_REFERENCED,CODENSA,DINERS,MASTERCARD,VISA,PSE" />
            </form> */}
        </React.Fragment>
    )
}

export default Payu