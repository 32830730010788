import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// COMPONENTES
import Menu from "./Menu";
import CoberturaModal from "../modal/CoberturaModal";


// REDUX
import { selectActiveUser } from "../../store/usuario/reducer";
import { selectActiveCarrito } from "../../store/carrito/reducer";

// IMAGENES
import anddress from "../../assets/icon/address.svg";
import perfil from "../../assets/icon/perfil.svg";
import carrito from "../../assets/icon/carrito.svg";
import Auth from "../../helpers/Auth";
import { RiHome4Fill } from "react-icons/ri";


import { FaShoppingCart } from "react-icons/fa";
import { BsSun } from "react-icons/bs";

const Sub_nav = (props) => {
  const [cantidad, setCantidad] = useState(0);
  const [modalCobertura, setModalCobertura] = useState(false);
  const [municipio, setMunicipio] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalDescon, setTotalDescont] = useState(0)
  const [empleado, setEmpleado] = useState(0)
  const [iva, setIva] = useState(0)

  const handleMenu = () => {
    document.querySelector(".menu").classList.toggle("show-menu");
  };

  const handleRedirect = (item) => {
    //props.history.push(item);
    window.location.href = item;
  };

  const getCantidad = () => {
    var cant = 0;
    let total = 0;
    let iva = 0;
    let cart = props.carrito || {};
    Object.keys(cart).map((item, i) => {
      cant += cart[item].cantidad;
      total += cart[item]["cantidad"] * cart[item]["precio"];
      let subtotal =  cart[item]["cantidad"] * cart[item]["iva"];
      iva += subtotal;
      setIva(iva)
    });
    setCantidad(cant);
    setTotal(total);
  };

  const showCobertura = () => {
    if (!Auth.getToken()) {
      setModalCobertura(true);
    }
  };

  useEffect(() => {
    console.log(props);
    setEmpleado(localStorage.getItem("ingresoEmpleado"))
    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) setMunicipio(ubicacion.municipio);
    else {
      setModalCobertura(true);
    }
    getCantidad();
  }, [props.carrito]);

  return (
    <React.Fragment>
      <div className="navmenu">
        <ul id="menu">
          <a className="tab" onClick={() => handleRedirect("/")}>
             <RiHome4Fill  color="#304695" size="2.4rem"  />
          </a>
          <li className="location">
            <a className="tab" onClick={() => showCobertura()}>
              <img
                src={anddress}
                alt="icon menu"
                width="20px"
                height="20px"
              ></img>
              {municipio ? municipio : "SIN UBICACIÓN"}
            </a>
          </li>

          <li>
            <a className="tab cart" onClick={() => handleRedirect("/carrito")}>
              <FaShoppingCart color="#304695" size="1.5rem"/>
              {cantidad > 0 && <span className="cant">{cantidad}</span>}
              {total > 0 && (
                <span className="total">
                  {props.usuario.accesoEmpleado == 1 ? (
                    <>
                    {"$" +
                    new Intl.NumberFormat("en", {
                      numberingSystem: "latn",
                      style: "decimal",
                      currency: "COP",
                    }).format(Math.round((total - iva) - (((total - iva ) * props.usuario.descuento) / 100) + (((total - iva) - ((total - iva) * props.usuario.descuento / 100)) *((iva * 100 / (total - iva))))/100))}
                    </>
                  ):(
                    <>
                    {" $" +
                    new Intl.NumberFormat("en", {
                      numberingSystem: "latn",
                      style: "decimal",
                      currency: "COP",
                    }).format(total)}
                    </>
                  )}
                  
                </span>
              )}
            </a>
          </li>
          <li className="profile">
            <a
              className="tab"
              onClick={() => handleMenu()}
              style={{ backgroundColor: props.color, opacity: "0.8" }}
            >
              <img
                src={perfil}
                alt="icon menu"
                width="15px"
                height="15px"
              ></img>
              {props.usuario ? (
                <div>
                <div>
                {props.usuario.nombre + " " + props.usuario.apellido}
                </div>
                <div>
                  {empleado == 1 ? (
                    <>Empleado</>
                  ):null}
                </div>
                </div>
              ): <> Ingresar / Registrarse </>}
            </a>
          </li>
         
        </ul>
      </div>
      <Menu history={props.history} />
      {modalCobertura ? (
        <CoberturaModal
          updateMunicipio={setMunicipio}
          showModal={setModalCobertura}
        />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
    carrito: selectActiveCarrito(state),
  };
};

export default withRouter(connect(mapStateToProps)(Sub_nav));
