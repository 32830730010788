import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Progress from '../util/Progress';
import Auth from '../../helpers/Auth';
import InputOtp from '../util/InputOtp';

//REDUX
import ActualizarUsuario from '../../store/usuario/action';

const VerificarEmail = (props) => {

    const [errorg, setErrorg] = useState("");
    const [error, setError] = React.useState("");
    //const [codigo, setCodigo] = useState();
    const [loader, setLoader] = useState(false);

    const handleVerificar = (codigo) => {
        console.log(codigo);
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSVERIFICACION
        axios.post(url, {
            'codigoVerificacion': codigo,
            'empleado' : props.empleado == true ? "ok" : "no"
        }).then((response) => {
            console.log(response);
            let change = response.data.cliente 
            props.ActualizarUsuario(change);
            Auth.setToken(response.data.token);
            Auth.setUbicacion({
                direccion: JSON.stringify(response.data.cliente.direcciones[0]),
                departamentoId: response.data.cliente.direcciones[0].departamentoId,
                departamento: response.data.cliente.direcciones[0].departamento,
                municipioId: response.data.cliente.direcciones[0].municipioId,
                municipio: response.data.cliente.direcciones[0].municipio
            })
            props.history.replace('/');
        }).catch((err) => {
            setLoader(false);

            if (err.response.data.errors) {
                setError(err.response.data.errors);
            }

            if (err.response.data.error) {
                setErrorg(err.response.data.error);
            }
        })
    }

    const handleReenviar = () => {
        setLoader(true);
        let url = Constants.URL_BASE + Constants.WSREENVIAR
        axios.post(url, {
            'email': props.email
        }).then((response) => {
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
            if (err.response.data.errors) {
                setError(err.response.data.errors);
            }
            if (err.response.data.error) {
                setErrorg(err.response.data.error);
            }
        })
    }


    return (

        <React.Fragment>
            {loader ? <Progress color={"#2098de"} /> : (
                <React.Fragment>
                    <div className="form-control">
                        <label style={{ 'font-size': '0.9rem' }}>
                            {props.empleado ? (
                                <>
                                por favor digita el código de verificación enviado al correo {props.email}
                                </>
                            ) : (
                                <>
                                Para terminar el proceso de registro, por favor digita el código de verificación enviado al correo {props.email}
                                </>
                            )}

                        </label>
                        <label className="minus"><span style={{ color: 'red' }}>*</span>Si no encuentras el correo, valida en los no deseados.</label>
                        <br />
                        <label>Código de verificación <span>*</span>:</label>
                        <InputOtp updateToken={handleVerificar} error={error} />
                        {/* <input 
                        className={error.CodigoVerificacion ? "input-error" : null}
                        type="text"
                        placeholder="Código de verificación"
                        name="codigo"
                        id="codigo"
                        onChange={e => setCodigo(e.target.value)}
                        value={codigo}>
                    </input>   */}
                        {error.CodigoVerificacion ? (
                            <span className="error">
                                <p>{error.CodigoVerificacion}</p>
                            </span>
                        ) : null}

                    </div>
                    {errorg.length > 0 ? (
                        <span className="error">
                            <b>{errorg}</b>
                        </span>
                    ) : null}
                    <div className="form-link">
                        <a onClick={() => handleReenviar()}>Volver a enviarme un código</a>
                    </div>
                    <button type="button" className="btn-large" onClick={handleVerificar}>{props.empleado ? 'Ingresar' : 'Verificar'}</button>
                </React.Fragment>

            )}
        </React.Fragment>

    )
}

export default withRouter(connect(null, { ActualizarUsuario })(VerificarEmail));