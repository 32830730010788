import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Constants from '../../constants/global';
import firebase from '../../constants/firebase';
import axios from 'axios';
import Auth from '../../helpers/Auth';

// COMPONENTES
import SucursalModal from '../modal/SucursalModal';
import AlertModal from '../modal/AlertModal';

// REDUX
import ActualizarUsuario from '../../store/usuario/action';
import { selectActiveUser } from '../../store/usuario/reducer';

// IMAGENES
import left from '../../assets/icon/left.svg';
import rigth from '../../assets/icon/rigth.svg';
import ReactGA from 'react-ga'

const Categoria = (props) => {

    const [alerta, setAlerta] = useState();
    const [categorias, setCategorias] = useState();
    const [active, setActive] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [scrollEnd, setScrollEnd] = useState(true);

    useEffect(() => {
        if(props.usuario.accesoEmpleado == undefined){
            categoriaData(localStorage.getItem("ingresoEmpleado"))
        }else{
            if(props.usuario.accesoEmpleado == 1){
                categoriaData(1)
            }else{
                categoriaData(0)
            }
            
        }
        setActive(props.categoriaId);
    }, [props.categoriaId])

    const categoriaData = (empleado) => {
        let categoriesUser = []
        let categoriesEmployed = []
        let url = Constants.URL_BASE + Constants.WSCATEGORIAS
        axios.get(url)
            .then(res => {
                let listData = res.data || [];
                if (listData.length == 0) {
                    listData.splice(0, 0, { codigo: -1, descripcion: "TODAS" });
                }
                listData.map((item) => {
                    if (item.tipo == "Usuario") {
                        categoriesUser.push(item)
                    } else {
                        categoriesEmployed.push(item)
                    }
                })
                props.handleCategoria(listData[0]);
                    
                    if (empleado == 1) {
                        setCategorias(categoriesEmployed);
                        console.log("e",categoriesEmployed);
                    } else {
                        setCategorias(categoriesUser);
                        console.log("U",categoriesUser);
                    }
                

            });
    };

    const scrollAction = (item) => {
        document.getElementById('scroll-categoria').scrollLeft += item;
    }

    const validate = (id) => {
        if (id == 5 || id == 6) {
            var cliente = props.usuario;
            if (cliente) {
                if (!cliente.fechaMayorEdad && id == 5) {
                    setAlerta({
                        tipo: "warning",
                        titulo: "¿Usted es mayor de edad?",
                        mensaje: "Recuerde que este tipo de productos solo puede ser vendidos a mayores de edad.",
                        btnOk: "Si",
                        btnCancel: "No",
                        fnOk: function () {
                            var cliente = props.usuario;
                            if (cliente) {
                                let fa = new Date();
                                let fe = new Date(fa.getFullYear(), fa.getMonth(), fa.toLocaleString('es-CO', { day: 'numeric' }), fa.getHours(), fa.getMinutes(), fa.getMilliseconds());
                                axios.put(Constants.URL_BASE + Constants.WSACTUALIZARMAYOR + cliente.id, {
                                    'fechaMayorEdad': fe.toISOString()
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${Auth.getToken()}`
                                    }
                                }).then((response) => {
                                    cliente.fechaMayorEdad = fe.toISOString();
                                    props.ActualizarUsuario(cliente);
                                    document.querySelector(".modal-sucursal").classList.remove("show-modal");
                                }).catch((err) => {
                                })
                            }
                        },
                        fnCancel: function () {
                            //window.location.reload(true);
                            props.history.push('/');
                        }
                    })
                }

                if (!cliente.fechaMayor14 && id == 6) {
                    setAlerta({
                        tipo: "warning",
                        titulo: "¿Usted es mayor de 14 años?",
                        mensaje: "Recuerde que este tipo de productos solo puede ser vendidos a mayores de 14 años.",
                        btnOk: "Si",
                        btnCancel: "No",
                        fnOk: function () {
                            var cliente = props.usuario;
                            if (cliente) {
                                let fa = new Date();
                                let fe = new Date(fa.getFullYear(), fa.getMonth(), fa.toLocaleString('es-CO', { day: 'numeric' }), fa.getHours(), fa.getMinutes(), fa.getMilliseconds());
                                axios.put(Constants.URL_BASE + Constants.WSACTUALIZARCATORCE + cliente.id, {
                                    'fechaMayorEdad': fe.toISOString()
                                }, {
                                    headers: {
                                        Authorization: `Bearer ${Auth.getToken()}`
                                    }
                                }).then((response) => {
                                    cliente.fechaMayor14 = fe.toISOString();
                                    props.ActualizarUsuario(cliente);
                                    document.querySelector(".modal-sucursal").classList.remove("show-modal");
                                }).catch((err) => {

                                })
                            }
                        },
                        fnCancel: function () {
                            props.history.push('/');
                        }
                    })
                }
            } else {
                if (!Auth.getToken()) {
                    setAlerta({
                        tipo: "info",
                        titulo: "Inicio de sesión.",
                        mensaje: "Por favor inicie sesión o registrese para ver esta categoria",
                        btnOk: "Ingresar o Registrarse",
                        btnCancel: "Cancelar",
                        fnOk: function () {
                            props.history.push('/login');
                        },
                        fnCancel: function () {
                            props.history.push('/');
                        }
                    })
                }

            }
        }

        setActive(id);
    }

    const clickCategoria = (item) => e => {
        ReactGA.event({
            category: "Categoria",
            action: "Categoria Seleccionada ",
            label: item.nombre,
            value: item.id
        });
        //firebase.analytics().logEvent("categoria", item.id + "-" + item.nombre);
        var id = item.id;
        var name = item.nombre;
        var res = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/ /g, "-") + "-" + id;
        //Auth.setCategoria(id)
        props.history.push("/catalogo/" + res);
    }

    setTimeout(function () {
        if (document.getElementById('scroll-categoria')) {
            if (document.getElementById('scroll-categoria').scrollLeft
                + document.getElementById('scroll-categoria').offsetWidth + 10
                >= document.getElementById('scroll-categoria').scrollWidth) {
                setScrollEnd(false);
            } else {
                setScrollEnd(true);
            }

            document.getElementById("scroll-categoria").onscroll = function () {

                setScroll(document.getElementById('scroll-categoria').scrollLeft);

                if (document.getElementById('scroll-categoria').scrollLeft
                    + document.getElementById('scroll-categoria').offsetWidth + 10
                    >= document.getElementById('scroll-categoria').scrollWidth) {
                    setScrollEnd(false);
                } else {
                    setScrollEnd(true);
                }
            }
        }

    }, 300);



    return (
        <React.Fragment>
            {categorias ? (
                <div className="categoria">
                    {scroll > 0 ? (
                        <div className="arrow"
                            onClick={() => scrollAction(-60)}>
                            <img src={left} alt="icon menu" width="20px" height="20px"></img>
                        </div>
                    ) : null}
                    <ul id="scroll-categoria">
                        {categorias.map((item, i) => (
                            <li key={i}>
                                <a
                                    className={active == item.id ? 'active' : null}
                                    onClick={clickCategoria(item)}>
                                    {item.nombre}
                                </a>
                            </li>
                        ))}
                    </ul>
                    {scrollEnd ? (
                        <div className="arrow" onClick={() => scrollAction(60)}>
                            <img src={rigth} alt="icon menu" width="20px" height="20px"></img>
                        </div>
                    ) : null}
                </div>
            ) : null}

            {alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state)
    }
}

export default withRouter(connect(mapStateToProps, { ActualizarUsuario })(Categoria));