import React, { useState, useEffect } from "react";
var CryptoJS = require("crypto-js");


const AvVillas = (props) => {

  const handleCifrar = (cadena) =>{
    var hash = CryptoJS.HmacSHA256(cadena, process.env.REACT_APP_PASSWORD)
    return CryptoJS.enc.Base64.stringify(hash)
  }

  const handleHash = (data) => {
    let cadena = [
        data.convenioId,
        data.transaccionConvenioId || "",
        data.referenciaPago1,
        data.referenciaPago2 || "",
        data.referenciaPago3 || "",
        data.referenciaPago4 || "",
        data.valor || "",
        data.urlRespuesta
      ].join("-")
      return handleCifrar(cadena)
  }

  const handleButton = () =>{
    let data = {
      convenioId:process.env.REACT_APP_ID,
      transaccionConvenioId: props.transaccionConvenioId,
      referenciaPago1:props.usuario.doc,
      referenciaPago2:props.referencia,
      descripcion:props.observacion ? props.observacion : "sin observaciones",
      valor:props.total,
      urlRespuesta: "https://tienda.superricas.com/respuesta/cpv/avvillas?transaccionConvenioId={transaccionConvenioId}&transaccionId={transaccionId}&aprobado={aprobado}&ref1={ref1}&ref2={ref2}&valor={valor}&uuid={uuid}"
    }

    data.hash = handleHash(data)
    handleSubmit(data)
  }

  const handleSubmit = (data) =>{
    var form = document.forms
    for(var item in data){
      let input = document.createElement('input')
      input.type="hidden"
      input.id=item
      input.name=item
      input.value=data[item]
      form[0].appendChild(input)
    }
    setTimeout(()=>{
      form[0].submit()
    },2000)
  }

  useEffect(() => {
      handleButton()
  }, []);

  return (
    <React.Fragment>
        <form id="formPayment" action={process.env.REACT_APP_PAYMENT} method="post"></form> 
    </React.Fragment>
  );
};

export default AvVillas;
