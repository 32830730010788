import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import firebase from "../../constants/firebase";
import Navbar from "../navbar/Navbar";
import Sub_nav from "../navbar/Sub_nav";
import Footer from "../footer/Footer";
import CardProducto from "../util/CardProducto";
import AlertModal from "../modal/AlertModal";
import axios from "axios";
import Auth from "../../helpers/Auth";
import Search from "../util/Search";
import * as Constants from "../../constants/global";
import Progress from "../util/Progress";

//REDUX
import ActualizarCarrito from "../../store/carrito/action";
import { selectActiveCarrito } from "../../store/carrito/reducer";
import { selectActiveUser } from "../../store/usuario/reducer";

import noimage from "../../assets/img/logo_III.png";
import minus from "../../assets/icon/minus.svg";
import plus from "../../assets/icon/plus.svg";

// import TagManager from "react-gtm-module";

const Detalle = (props) => {
  //const [search, setSearch] = useState("");
  const [alerta, setAlerta] = useState();
  const [producto, setProducto] = useState();
  const [recomenado, setRecomendado] = useState([]);
  const [loader, setLoader] = useState(false);
  const [topPedido, setTopePedido] = useState();
  const [cantidad, setCantidad] = useState()

  // AUMENTA LA CANTTIDAD
  const handleMas = (item) => {
    if (Auth.getToken()) {

      let cuenta = 0
      console.log(item);
      var ubicacion = Auth.getUbicacion();
      if (Object.keys(ubicacion).length > 0) {
        /* firebase
          .analytics()
          .logEvent("agregar_carrito", item.codigoSku + "-" + item.nombre); */
        var carrito = props.carrito || {};
        console.log(carrito);
        if (carrito[item.codigoSku]) {
          //if (carrito[item.codigoSku]["cantidad"]){
          if (carrito[item.codigoSku]["cantidad"] < item.inventario) {
            cuenta = carrito[item.codigoSku]["cantidad"] += 1;
            carrito[item.codigoSku]["cantidad"] += 1;
            carrito[item.codigoSku]["cantidad"] -= 1;
            setCantidad(cuenta)
          } else {
            handleSinInventario(item.inventario);
          }
          /* }else {
            props.setAlerta({
              tipo: "error",
              titulo: "Error.",
              mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
              btnOk: "Aceptar",
              fnOk: function () {},
              fnCancel: null,
            });
          } */
        } else {
          if (item.inventario > 0) {
            carrito[item.codigoSku] = {
              id: item.id,
              nombre: item.nombre,
              precio: parseFloat(item.precio),
              iva: item.iva,
              ico: parseInt(item.ico),
              cantidad: 1,
              inventario: item.inventario,
              descuento: parseInt(item.descuento),
              urlImagen: item.imagen == null || item.imagen.length < 2 ? item.codigoSku : item.imagen
            };
          } else {
            handleSinInventario(item.inventario);
          }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());

        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: "addToCart",
        //     ecommerce: [
        //       {
        //         currencyCode: "COP",
        //         add: {
        //           products: [
        //             {
        //               name: item.nombre,
        //               id: item.codigoSku,
        //               price: item.precio,
        //               category: item.categoria,
        //               quantity: 1,
        //             }
        //           ]
        //         }
        //       }
        //     ]
        //   }
        // });
      } else {
        props.ActualizarCarrito({});
      }
    } else {
      setAlerta({
        tipo: "info",
        titulo: "Inicia sesión",
        mensaje: "Si deseas agregar este producto a tu pedido, por favor inicia sesión.",
        btnOk: "Iniciar sesión",
        btnCancel: "Cancelar",
        fnOk: function () {
          props.history.push("/login")
        },
        fnCancel: function () { }
      })
    }
  };

  const clickCategoria = (categoriaId, categoria) => {
    var id = categoriaId;
    var name = categoria;
    var res =
      name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, "-") +
      "-" +
      id;
    props.history.push("/catalogo/" + res);
  };

  const handleMenos = (item) => {
    let cuenta = 0
    var carrito = props.carrito || {};
    if (carrito[item.codigoSku]) {
      if (carrito[item.codigoSku]["cantidad"] == 1) {
        delete carrito[item.codigoSku];
      } else {
        if (carrito[item.codigoSku]["cantidad"] >= 1) {
          carrito[item.codigoSku]["cantidad"] -= 1;
          carrito[item.codigoSku]["cantidad"] += 1;
          cuenta = carrito[item.codigoSku]["cantidad"] -= 1;
          setCantidad(cuenta)
        }
      }
    }
    Auth.setCarrito(carrito);
    props.ActualizarCarrito(Auth.getCarrito());

    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'removeFromCart',
    //     ecommerce: {
    //       remove: {
    //         products: [{
    //           'name': item.nombre,
    //           'id': item.id,
    //           'price': item.precio,
    //           'category': item.categoria,
    //           'quantity': 1
    //         }]
    //       }
    //     }
    //   }
    // });
  };
  const cantidadProducto = (item) => {
    var carrito = props.carrito || {};
    let cantidad = 1

    if (cantidad == 1) {
      return 1;

    } else {
      return 1;

    }




  };

  const handleChangeCantidad = (item, valor) => {
    if (Auth.getToken()) {

      var carrito = props.carrito || {};
      setCantidad(valor)
      if (parseInt(valor) > item.inventario) {
        carrito[item.codigoSku]["cantidad"] = 0;
        delete carrito[item.codigoSku];
        setCantidad(0)
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
        setAlerta({
          tipo: "info",
          titulo: "Sin Inventario.",
          mensaje:
            "Lo sentimos, la cantidad disponible para este producto es de " +
            item.inventario +
            " unidades",
          btnOk: "Aceptar",
          fnOk: function () { },
          fnCancel: null,
        });
      } else {

        if (valor <= 0) {
          delete carrito[item.codigoSku];
        } else {
          if (carrito[item.codigoSku]) {

            if (valor) {
              if (parseInt(valor) <= item.inventario) {
                carrito[item.codigoSku]["cantidad"] = parseInt(valor);

              } else {
                setAlerta({
                  tipo: "info",
                  titulo: "Sin Inventario.",
                  mensaje:
                    "Lo sentimos, la cantidad disponible para este producto es de " +
                    item.inventario +
                    " unidades",
                  btnOk: "Aceptar",
                  fnOk: function () { },
                  fnCancel: null,
                });
              }
            } else {
              props.setAlerta({
                tipo: "error",
                titulo: "Error.",
                mensaje: "¿Lo sentimos, la cantidad máxima por producto es 99",
                btnOk: "Aceptar",
                fnOk: function () { },
                fnCancel: null,
              });
            }
          } else {
            carrito[item.codigoSku] = {
              id: item.id,
              nombre: item.nombre,
              precio: item.precio,
              cantidad: parseInt(valor),
              iva: item.iva,
              ico: parseInt(item.ico),
              inventario: item.inventario,
              descuento: parseInt(item.descuento),
              urlImagen: item.imagen == null || item.imagen.length < 2 ? item.codigoSku : item.imagen
            };
            // TagManager.dataLayer({
            //   dataLayer: {
            //     event: "addToCart",
            //     ecommerce: [
            //       {
            //         currencyCode: "COP",
            //         add: {
            //           products: [
            //             {
            //               name: item.nombre,
            //               id: item.codigoSku,
            //               price: item.precio,
            //               category: item.categoria,
            //               quantity: parseInt(valor),
            //             }
            //           ]
            //         }
            //       }
            //     ]
            //   }
            // });
          }
        }
        Auth.setCarrito(carrito);
        props.ActualizarCarrito(Auth.getCarrito());
      }
    } else {
      setAlerta({
        tipo: "info",
        titulo: "Inicia sesión",
        mensaje: "Si deseas agregar este producto a tu pedido, por favor inicia sesión.",
        btnOk: "Iniciar sesión",
        btnCancel: "Cancelar",
        fnOk: function () {
          props.history.push("/login")
        },
        fnCancel: function () { }
      })
    }
  };

  const handleSinInventario = (inventario) => {
    setAlerta({
      tipo: "info",
      titulo: "Sin Inventario.",
      mensaje:
        "Lo sentimos, la cantidad disponible para este producto es de " +
        inventario +
        " unidades",
      btnOk: "Aceptar",
      fnOk: function () { },
      fnCancel: null,
    });
  };

  const loadRecomendado = (id) => {
    setLoader(true);
    let url =
      Constants.URL_BASE +
      Constants.WSPRODUCTOS +
      "/Recomendado?ProductoId=" +
      id;
    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) {
      let poligono;
      if (ubicacion.direccion)
        poligono = (JSON.parse(ubicacion.direccion))
      url +=
        "&DepartamentoId=" +
        ubicacion.departamentoId +
        "&MunicipioId=" +
        ubicacion.municipioId
      if (poligono) {
        url +=
          "&latitud=" +
          poligono.latitud +
          "&longitud=" +
          poligono.longitud
      }
    }
    if (props.usuario) {
      if (props.usuario.accesoEmpleado != 0) {
        url += "&empleado=" + props.usuario.accesoEmpleado + "&codigoBodega=" + props.usuario.bodega
        url += "&bodegaUser=" + props.usuario.bodega
      } else {
        url += "&bodegaUser=" + props.usuario.bodega
      }
    } else {
      url += "&bodegaUser=11060"
    }
    axios.get(url).then((res) => {
      setRecomendado(res.data);
      setLoader(false);
    });
  };

  const loadProducto = (id) => {
    console.log(id);
    let url = Constants.URL_BASE + Constants.WSPRODUCTOS + "/Detalle?ProductoId=" + id;
    var ubicacion = Auth.getUbicacion();
    if (Object.keys(ubicacion).length > 0) {
      let poligono;
      if (ubicacion.direccion)
        poligono = (JSON.parse(ubicacion.direccion))
      url +=
        "&DepartamentoId=" +
        ubicacion.departamentoId +
        "&MunicipioId=" +
        ubicacion.municipioId
      if (poligono) {
        url +=
          "&latitud=" +
          poligono.latitud +
          "&longitud=" +
          poligono.longitud
      }
    }
    if (props.usuario) {
      if (props.usuario.accesoEmpleado != 0) {
        url += "&empleado=" + props.usuario.accesoEmpleado + "&codigoBodega=" + props.usuario.bodega
        url += "&bodegaUser=" + props.usuario.bodega
      } else {
        url += "&bodegaUser=" + props.usuario.bodega
      }
    } else {
      url += "&bodegaUser=11060"
    }
    axios.get(url).then((res) => {
      console.log(res);
      console.log(res.data);
      setProducto(res.data);
      // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: "detail",
      //     ecommerce:
      //     {
      //       detail: {
      //         actionField: {
      //           list: "Detalle producto " + res.data.nombre,
      //         },
      //         products: [
      //           {
      //             name: res.data.nombre,
      //             id: res.data.codigoSku,
      //             price: res.data.precio,
      //             category: res.data.categoria,
      //           }
      //         ]
      //       },
      //     },
      //   }
      // });
      // TagManager.dataLayer({
      //   dataLayer: {
      //     ecommerce: {
      //       detail: {
      //         actionField: {
      //           list: 'Apparel Gallery'
      //         },
      //         products: [
      //           {
      //             'name': res.data.nombre,
      //             'id': res.data.id,
      //             'price': res.data.precio,
      //             'category': res.data.categoria
      //           }
      //         ]
      //       }
      //     }
      //   }
      // });
    });
  };

  const topePedido = () => {
    let url = Constants.URL_BASE + Constants.WSTOPEPEDIDO;
    axios.get(url).then((response) => {
      if (props.usuario.accesoEmpleado != 0) {
        setTopePedido(30000);
      } else {
        setTopePedido(response.data.valor);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.match.params.producto) {
      var urlProduct = props.match.params.producto;
      var productArray = urlProduct.split("-");
      var productId = productArray[productArray.length - 1];
      loadProducto(productId);
      loadRecomendado(productId);
      topePedido()

    }
  }, [props.match.params.producto]);

  return (
    <React.Fragment>
      <div className="content-carrito">
        <div className="navbar principal navcarrito">
          <Navbar item={2} />
          {/* <Search search={search} updateSearch={handleSearch} /> */}
          <Sub_nav />
        </div>
        <div className="layout">
          {producto ? (
            <>
              <MetaTags>
                <title>{producto.nombre}</title>
                <meta
                  property="og:description"
                  content={producto.descripcion}
                />
                <meta
                  property="og:title"
                  content={producto.nombre.toLowerCase()}
                />
                <meta property="og:url" content={`https://ticket.hu/`} />
                <meta
                  property="product:retailer_item_id"
                  content={producto.id}
                />
                <meta
                  property="og:image"
                  content={
                    Constants.URL_BASE +
                    "/image/" +
                    producto.codigoSku.trim() +
                    ".png"
                  }
                />
                <meta
                  property="product:price:amount"
                  content={producto.precio}
                />
                <meta property="product:price:currency" content="HUF" />
                <meta
                  property="product:availability"
                  content="available for order"
                />
                <meta property="product:category" content="499969" />
              </MetaTags>
              <div className="detalle">
                <div className="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Inicio</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a
                        onClick={() =>
                          clickCategoria(
                            producto.categoriaId,
                            producto.categoria
                          )
                        }
                      >
                        {producto.categoria}
                      </a>
                    </li>
                    <li className="breadcrumb-item active">
                      {producto.nombre}
                    </li>
                  </ol>
                </div>
                <div className="detail-product">
                  <div className="detail-content">
                    <div className="detail-gallery">
                      {producto.descuento > 0 && (
                        <div className="card-info-vi">
                          {"-" + producto.descuento + "%"}
                        </div>
                      )}
                    </div>
                    <div className="detail-photo">
                      {producto.imagen == null || producto.imagen.length == 0 ? (
                        <img
                          style={producto.agotado == 1 ? { opacity: 0.3 } : {}}
                          className="img-product"
                          src={
                            Constants.URL_BASE + "/image/" + producto.codigoSku.trim() + ".png"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noimage;
                          }}
                        ></img>
                      ) : (
                        <img
                          style={producto.agotado == 1 ? { opacity: 0.3 } : {}}
                          className="img-product"
                          src={
                            Constants.URL_BASE +
                            "/image/" + producto.imagen + ".png"
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noimage;
                          }}
                        ></img>
                      )}

                    </div>
                    <div className="detail-info">
                      <p className="detail-shop">SuperRicas</p>
                      <h2 className="detail-title">
                        <b>{producto.nombre}</b>
                      </h2>
                      <p className="detail-description">{producto.categoria}</p>
                      <p className="detail-description">
                        <b>Descripción:</b>
                      </p>
                      <p className="detail-description">
                        {producto.descripcion}
                      </p>
                      <div className="detail-additional">
                        <p>
                          Quedate en casa, nosotros te llevamos el domicilio
                        </p>
                        <p className="envio">
                          Envío gratis por compras mayores a ${topPedido}
                        </p>
                      </div>
                      {producto.descuento > 0 && (
                        <React.Fragment>
                          <p className="line-through">
                            {" $" +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(
                                Math.round(
                                  (producto.precio * 100) /
                                  (100 - producto.descuento)
                                )
                              )}
                          </p>
                        </React.Fragment>
                      )}
                      {cantidad == undefined ? (
                        <React.Fragment>
                          <p className="price-Total-D" style={{ color: "#263B93" }}>Total</p>
                          <p className="detail-price">
                            {"$ " +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(producto.precio || 0)}
                            &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                            <span style={{ color: "#263B93" }}>
                              {" $" +
                                new Intl.NumberFormat("en", {
                                  numberingSystem: "latn",
                                  style: "decimal",
                                  currency: "COP",
                                }).format(producto.precio * 1)}

                            </span>
                          </p>

                        </React.Fragment>) :
                        (
                          <React.Fragment>
                            <p className="price-Total-D" style={{ color: "#263B93" }}>Total</p>
                            <p className="detail-price">
                              {"$ " +
                                new Intl.NumberFormat("en", {
                                  numberingSystem: "latn",
                                  style: "decimal",
                                  currency: "COP",
                                }).format(producto.precio || 0)}
                              &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                              <span style={{ color: "#263B93" }}>
                                {" $" +
                                  new Intl.NumberFormat("en", {
                                    numberingSystem: "latn",
                                    style: "decimal",
                                    currency: "COP",
                                  }).format(producto.precio * cantidad)}
                              </span>
                            </p>
                          </React.Fragment>)}


                      {producto.agotado == 0 ? (
                        <div className="action2">
                          {props.carrito[producto.codigoSku] ? (
                            <div className="controls">
                              <button
                                role="button"
                                onClick={() => handleMenos(producto)}
                              >
                                <img
                                  src={minus}
                                  alt="icon minus"
                                  width="15px"
                                  height="15px"
                                ></img>
                              </button>
                              <input
                                type="number"
                                minLength="0"
                                maxLength="99"
                                onChange={(e) =>
                                  handleChangeCantidad(producto, e.target.value)
                                }
                                value={
                                  props.carrito[producto.codigoSku].cantidad
                                }
                              />
                              <button
                                role="button"
                                onClick={() => handleMas(producto)}
                              >
                                <img
                                  src={plus}
                                  alt="icon plus"
                                  width="15px"
                                  height="15px"
                                ></img>
                              </button>
                            </div>
                          ) : (
                            <div className="controls">
                              <button
                                role="button"
                                onClick={() => handleMenos(producto)}
                              >
                                <img
                                  src={minus}
                                  alt="icon minus"
                                  width="15px"
                                  height="15px"
                                ></img>
                              </button>
                              <input
                                type="number"
                                minLength="0"
                                maxLength="99"
                                onChange={(e) =>
                                  handleChangeCantidad(producto, e.target.value)
                                }
                                value={""}
                              />
                              <button
                                role="button"
                                onClick={() => handleMas(producto)}
                              >
                                <img
                                  src={plus}
                                  alt="icon plus"
                                  width="15px"
                                  height="15px"
                                ></img>
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="agotado">
                          <p>Agotado</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {recomenado && (
                  <React.Fragment>
                    <div className="inicio-titulo">
                      <h2>Recomendados</h2>
                    </div>
                    <div className="pedido-list">
                      <div className="product-card">
                        {loader ? (
                          <Progress color={"#2098de"} />
                        ) : (
                          recomenado.map((item, i) => (
                            <CardProducto
                              key={i}
                              producto={item}
                              setAlerta={setAlerta}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {alerta ? <AlertModal alerta={alerta} updateAlerta={setAlerta} /> : null}
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
    carrito: selectActiveCarrito(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { ActualizarCarrito })(Detalle)
);
