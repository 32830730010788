import React, { useState, useEffect } from 'react';
import Auth from '../../helpers/Auth';

// IMAGENES
import datos from '../../assets/icon/datos.svg';
import information from '../../assets/icon/information.svg';
import history from '../../assets/icon/history.svg';
import exit from '../../assets/icon/exit.svg';

const Menu = (props) => {

    const handleRedirect = item => {
        props.history.push(item);
    }

    const handleLogout = () => {
        Auth.signout();
        caches.keys().then(function (names) {
            for (let name of names)
                caches.delete(name);
        });
        localStorage.clear()
        window.location.reload(true);
    }

    return (
        <div className="menu">
            <ul>
                {Auth.getToken() ? (
                    <React.Fragment>
                        <li>
                            <a onClick={() => handleRedirect('/datos')}>
                                <img src={datos} width="15px" height="15px" />
                                Mis datos
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/pedidos')}>
                                <img src={history} width="15px" height="15px" />
                                Historial Pedidos
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/preguntas-frecuentes')}>
                                <img src={information} width="15px" height="15px" />
                                Preguntas frecuentes
                            </a>
                        </li>
                        {/* <li>
                            <a href="/manual" target="_blank">
                                <img src={information}  width="15px" height="15px" />
                                Manual de usuario
                            </a>
                        </li> */}
                        <li>
                            <a onClick={() => handleLogout()}>
                                <img src={exit} width="15px" height="15px" />
                                Salir
                            </a>
                        </li>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <li>
                            <a onClick={() => handleRedirect('/login')}>
                                <img src={datos} width="15px" height="15px" />
                                Ingresar
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/cobertura')}>
                                <img src={datos} width="15px" height="15px" />
                                Registrarse
                            </a>
                        </li>
                        <li>
                            <a onClick={() => handleRedirect('/preguntas-frecuentes')}>
                                <img src={information} width="15px" height="15px" />
                                Preguntas frecuentes
                            </a>
                        </li>
                        {/* <li>
                            <a href="/manual" target="_blank">
                                <img src={information}  width="15px" height="15px" />
                                Manual de usuario
                            </a>
                        </li> */}
                    </React.Fragment>
                )}

            </ul>
        </div>
    )
}

export default Menu;