import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// COMPONENTS
import Navbar from "../navbar/Navbar";
import Sub_nav from "../navbar/Sub_nav";
import Marca from "../navbar/Marca";
import Categoria from "../navbar/Categoria";
import Footer from "../footer/Footer";
import CardProducto from "../util/CardProducto";
import AlertModal from "../modal/AlertModal";
import PublicidadModal from "../modal/PublicidadModal";
import Auth from "../../helpers/Auth";
import Search from "../util/Search";

import * as Constants from "../../constants/global";
import axios from "axios";
import { StickyContainer, Sticky } from "react-sticky";

// import TagManager from 'react-gtm-module'

// IMAGEN
import descargapp from "../../assets/img/descargapp.png";
import noimage from "../../assets/img/logo_III.png";
import rigth from "../../assets/icon/rigth.svg";
import bannerPruebas from '../../assets/banner/banner_sr_user.png'

// REDUX
import { connect } from "react-redux";
import { selectActiveUser } from "../../store/usuario/reducer";
import { Cookies } from "react-cookie";


const cookies = new Cookies();

const Inicio = (props) => {
  const [urlBanner, setUrlBanner] =useState("")
  const [search, setSearch] = useState("");
  const [alerta, setAlerta] = useState();
  const [categorias, setCategorias] = useState();
  const [popup, setPopup] = useState();
  const [banner, setBanner] = useState();
  const [productos, setProductos] = useState();
  const options = {
    margin: 10,
    responsiveClass: true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 3,
      },
      400: {
        items: 4,
      },
      600: {
        items: 6,
      },
      700: {
        items: 7,
      },
      1000: {
        items: 11,
      },
    },
  };

  const categoriaData = () => {
    let url = Constants.URL_BASE + Constants.WSCATEGORIAS;

    axios.get(url).then((res) => {
      let listData = res.data || [];
      if (listData.length == 0) {
        listData.splice(0, 0, { codigo: -1, descripcion: "TODAS" });
      }
      setCategorias(listData);
    });
  };

  // const bannerData = () => {
  //   let url = Constants.URL_BASE + Constants.WSBANNER;
  //   axios.get(url).then((res) => {
  //     let result = res.data
  //     result.map((item)=>{
  //       if(props.usuario){
  //         if(props.usuario.accesoEmpleado != 1){
  //           if(item.tipo == 1){
  //             setBanner(item.imagen)
  //           }
  //         }else{
  //           if(item.tipo == 2){
  //             setBanner(item.imagen)
  //           }
  //         }
  //       }else{
  //         if(item.tipo == 1){
  //           setBanner(item.imagen)
  //         }
  //       }
  //     })
  //   });
  // };

  const bannerData = () => {
    let url = Constants.URL_BASE + Constants.WSBANNER;
    axios.get(url).then((res) => {
      console.log(res);
      
      if(props.usuario.accesoEmpleado != 1){
        let dataFilter = res.data.filter(function (item) {
          return item.tipo == 1;
        })
        setBanner(dataFilter);
        console.log(dataFilter);
      }else{
        let dataFilter = res.data.filter(function (item) {
          return item.tipo == 2;
        })
        console.log(dataFilter);
        setBanner(dataFilter);
      }
      

    });
  };


  const popupData = () => {
    const popCookie = cookies.get("publicidad");
    if (!popCookie) {
      let url = Constants.URL_BASE + Constants.WSPOPUP;
      axios.get(url).then((res) => {
        setPopup(res.data);
      });
    }
  };

  const productoHomeData = () => {
    let url = Constants.URL_BASE + Constants.WSPRODUCTOHOME;
    var ubicacion = Auth.getUbicacion();
      url += "Empleados"
    if (Object.keys(ubicacion).length > 0) {
      let poligono;
      if (ubicacion.direccion)
        poligono = (JSON.parse(ubicacion.direccion))
      url +=
        "?DepartamentoId=11"
        +
        "&MunicipioId=" +
        ubicacion.municipioId
      if (poligono) {
        url +=
          "&latitud=" +
          ubicacion.departamentoId +
          "&longitud=" +
          ubicacion.municipioId
      }
      if(props.usuario){
        if (props.usuario.accesoEmpleado != 0) {
          url += "&empleado=" + props.usuario.accesoEmpleado + "&codeBodega=" + props.usuario.bodega
        }
        url += "&bodegaUser=" + props.usuario.bodega
      }else{
        url += "&empleado=0"
        url += "&bodegaUser=11060"
      }
      
    }
    
    axios
      .get(url)
      .then((res) => {
        console.log("home",res);
        setProductos(res.data);
        let listProducts = res.data;
        let impressions = [];
        let cont = 0
        listProducts.map((item, i) => {
          item.producto.map((product, j) => {
            impressions.push({
              'name': product.nombre,
              'id': product.codigoSku,
              'category': product.categoria,
              'list': 'Home',
              'position': cont++
            })
          })
        })

        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: 'eec.impressionView',
        //     ecommerce: {
        //       impressions: impressions
        //     }
        //   }
        // })

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickItem = (item) => {
    window.location.href = banner[item].enlace;
  };

  const clickCategoria = (item) => {
    var id = item.id;
    var name = item.nombre;
    var res =
      name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/ /g, "-") +
      "-" +
      id;
    props.history.push("/catalogo/" + res);
  };

  const handleCategoria = (item) => { };

  const handleSearch = (value) => {
    props.history.push("/catalogo/packs-15?q=" + value);
  };

  useEffect(() => {
    popupData();
    bannerData();
    setTimeout(() => {
      productoHomeData();
      categoriaData();
    }, 500);

  }, [props.usuario]);

  return (
    <React.Fragment>
      <div className="content-carrito">
        <div className="navbar">
          <Navbar item={2} />
          <Search search={search} updateSearch={handleSearch} />
          <Sub_nav />
          <Categoria categoriaId={null} handleCategoria={handleCategoria} />
        </div>

        <div className="layout">
          <div className="inicio">
            {banner && (
              <div className="banner">
                <Carousel
                  showArrows={true}
                  showThumbs={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  showStatus={false}
                  onClickItem={onClickItem}
                >
                    {banner.map((item, i) => (
                    <div>
                      
                      <img
                        className="img-banner"
                        src={Constants.URL_BASE + "banner/" + item.imagen}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noimage;
                        }}
                      ></img>
                    </div>
                  ))}

              
                </Carousel>
              </div>
            )}

            {/* {categorias && (
                            <React.Fragment>
                                <div className="inicio-titulo">
                                    <h2>
                                        Categorias 
                                    </h2>
                                    <a href="/catalogo/gaseosas-7">
                                        Ver Catálogo
                                        <img src={rigth} alt="icon left" width="10px" height="10px"></img>
                                    </a> 
                                </div>
                                <div className="inicio-categoria">
                                    <OwlCarousel
                                    className="owl-theme"
                                    {...options}>
                                        { categorias.map((item, i)=>(
                                            <div className="item">
                                                <div onClick={() => clickCategoria(item)}>
                                                    <a>
                                                        {item.nombre}
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </React.Fragment>
                            
                        )} */}

            {/* <Marca /> */}

            {productos && (
              <React.Fragment>
                {productos.map((item, i) => (
                  <React.Fragment>
                    <div className="inicio-titulo">
                      <h2>{item.nombre}</h2>
                      <a onClick={() => clickCategoria(item)}>
                        Ver todo
                        <img
                          src={rigth}
                          alt="icon left"
                          width="10px"
                          height="10px"
                        ></img>
                      </a>
                    </div>
                    <div className="pedido-list">
                      <div className="product-card">
                        {item.producto.map((producto, j) => (
                          <CardProducto
                            key={i}
                            producto={producto}
                            setAlerta={setAlerta}
                          />
                        ))}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      {/* <div className="descargaapp">
                <img src={descargapp} />
            </div> */}

      <Footer />
      {popup ? <PublicidadModal popup={popup} /> : null}
      {alerta ? <AlertModal alerta={alerta} updateAlerta={setAlerta} /> : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario: selectActiveUser(state),
  };
};

export default connect(mapStateToProps)(Inicio);

