import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import Navbar from '../navbar/Navbar'
import Sub_nav from '../navbar/Sub_nav'
import Footer from '../footer/Footer'
import axios from 'axios'
import { withRouter } from "react-router-dom"
import { FcHighPriority } from "react-icons/fc";
import * as Constants from '../../constants/global'
import Auth from '../../helpers/Auth'

import Avvillas from "../payment/avvillas";
import Payu from '../payment/payu';

import success from '../../assets/icon/success.svg'
import errors from '../../assets/icon/error.svg'
import PSE from '../../assets/img/BotonPSE.png'
import PAYU from '../../assets/img/BotonPayu.png'
import noimage from '../../assets/img/logo_III.png'

// REDUX
import ActualizarCarrito from '../../store/carrito/action';

// GOOGLE TAG MANAGER
// import TagManager from 'react-gtm-module'
var md5 = require('md5')

const ThankYouPage = (props) => {

    const [message, setMessage] = useState("");
    const [avVillas, setAvVillas] = useState(false);
    const [methodOnline, setMethodOnline] = useState(0)
    const [totalFinal, setTotalFinal] = useState(props.data.totalDescuento == 0 ? props.data.totalPay : props.data.totalDescuento)

    const loadPage = (order) => {
        let total = props.data.totalDescuento == 0 ? props.data.totalPay : props.data.totalDescuento
        console.log(totalFinal);
        let carrito = (Auth.getCarrito())
        let dataProducts = []
        Object.keys(carrito).map((item, i) => {
            dataProducts.push({
                name: carrito[item]['nombre'],
                id: carrito[item]['codigoSku'],
                price: carrito[item]['precio'],
                category: carrito[item]['categoria'],
                quantity: carrito[item]['cantidad']
            });
        })
        Auth.removeCarrito();
        props.ActualizarCarrito({});
        axios.get(Constants.URL_BASE + "Pedido/orden/" + props.data.referencia, {
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`
            }
        })
            .then((response) => {
                console.log(response);
                // console.log(response);
                // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
                // TagManager.dataLayer({
                //     dataLayer: {
                //         event: 'checkout',
                //         ecommerce: {
                //             checkout: {
                //                 actionField: {
                //                     step: 4
                //                 },
                //                 products: dataProducts
                //             }
                //         }
                //     }
                // })
                console.log(totalFinal);
                    if (props.data.confirmationPage) {
                        if (props.data.observacion == "APPROVED") {
                            setMessage(response.data.message + (props.data.typePay ? message : `. Referencia de pago:  ${props.data.respuestaPagos}`));
                        } else {
                            setMessage("su pago no pudo ser procesado por favor intente nuevamente");
                        }
                    } else {
                        if (response.data.datalayer.metodoPago == "NOMINA") {
                            setMessage(response.data.message + ". Que serán descontados por nómina.")
                        } else {
                            setMessage("El pedido " + props.data.referencia  + " fue recibo con exito, Valor: $" + new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(props.data.totalPay) +  (props.data.typePay ? message : ". pague en efectivo cuando reciba."));
                        }
                    } 
                // let dataLayer = response.data.datalayer;
                // window.google_tag_manager["GTM-TFCJNMK"].dataLayer.reset();
                // TagManager.dataLayer({
                //     dataLayer: {
                //         event: 'eec.purchase',
                //         ecommerce: {
                //             purchase: {
                //                 actionField: {
                //                     id: dataLayer.numeroDoc,
                //                     revenue: dataLayer.total,
                //                     /* affiliation: '',        
                //                     tax: 0,        
                //                     shipping: 0 */
                //                 },
                //                 products: dataProducts
                //             }
                //         }
                //     }
                // });
            })
            .catch((err) => {
                props.history.push("/");
            });
    }

    const handleRedirectPSE = () => {
        setTimeout(() => {
            setAvVillas(true)
        }, 3000)
    }

    const redirectPayment = () => {
        if (props.data.typePay) {
            handleRedirectPSE()
        }
    }
    useEffect(() => {
        // let total = props.data.totalDescuento == 0 ? props.data.totalPay : props.data.totalDescuento
        // setTotalFinal(total)
        console.log(props.data.totalPay);
        console.log(typeof(props.data.totalPay));
        let total = props.data.totalDescuento == 0 ? props.data.totalPay : props.data.totalDescuento
        console.log(total);
        console.log(typeof(total));
        let metodoOnline = localStorage.getItem("methodOnline")
        setMethodOnline(metodoOnline);
        var urlOrder = props.match.params.orden;
        loadPage(urlOrder);
    }, [])

    return (
        <React.Fragment>
            <div className="layout">
                <div className="mobile">
                    <div className="thank">
                        <div className="thank-you">
                            <h1>{props.data.typePay ? "Gracias por preferirnos" : "Gracias por tu compra"}</h1>
                            <br />
                            <h2>{props.data.typePay ? methodOnline == 1 ? "Ha elegido el metodo de pago en linea (PSE)" : "Ha elegido el metodo de pago en linea (payU)" : null}</h2>
                            <br />
                            <h3>{props.data.typePay ? methodOnline == 1 ? "En breve sera redirigido a la plataforma de pagos, si no es redirigido por favor precione el Boton PSE" : "En breve sera redirigido a la plataforma de pagos, si no es redirigido por favor precione el Boton payU" : null}</h3>
                            {props.data.confirmationPage ? (
                                <div className="alert-icon">
                                    <img
                                        src={props.data.observacion == "APPROVED" ? success : errors}
                                        alt="icon alert"
                                        onError={(e) => { e.target.onerror = null; e.target.src = noimage }} />
                                </div>
                            ) : (
                                <div className="alert-icon">
                                    <img
                                        src={props.data.typePay ? methodOnline == 1 ? PSE : PAYU : success}
                                        alt="icon alert"
                                        onError={(e) => { e.target.onerror = null; e.target.src = noimage }} />
                                </div>
                            )}
                            <div className="alert-content">
                                <p className="alert-title">Pedido</p>
                                <p className="alert-message">
                                    {message}
                                </p>
                            </div>
                            <div className="alert-buttons">
                                {/* <button type="submit" className="btn-large" onClick={() => setAvVillas(true)}>PAGO EN LINEA</button>  */}
                                {props.data.typePay ?
                                    (
                                        <>
                                            <div style={{ display: "flex" }}>
                                                <FcHighPriority
                                                    color="red"
                                                    size={30}
                                                    style={{ marginRight: "10px" }}
                                                />
                                                <p>Recuerda que tiene un maximo de 30 minutos para completar la compra</p>
                                            </div>
                                            <br />
                                            <button
                                                type="submit"
                                                className="btn-large"
                                                onClick={() => redirectPayment()}
                                            >Continuar
                                            </button>
                                        </>
                                    ) : (
                                        <React.Fragment>
                                    <button type="submit" className="btn-large btn-pago" onClick={() => props.history.push("/pedidos")}>{"Historial"}</button>
                                    <button type="submit" className="btn-large outline" onClick={() => props.history.push("/")}>Ir al catalogo</button>
                                </React.Fragment>
                                    )
                                }
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {avVillas ? (
                <>
                    {methodOnline == 2 ? (
                        <>
                            <Payu 
                                referencia={props.data.referencia}
                                observacion={props.data.observacion}
                                total={props.data.totalPay}
                                usuario={props.data.usuario}
                                transaccionConvenioId={props.data.transaccionConvenioId}
                                //produccion
                                encripte={md5(`tdVym7UgcP54bmAtw4g6mxRUYY~959580~${props.data.referencia}~${props.data.totalPay}~COP~AMEX,CODENSA,DINERS,MASTERCARD,VISA,PSE`)}
                            //Pruebas
                            // encripte={md5(`4Vj8eK4rloUd272L48hsrarnUA~508029~${props.data.referencia}~${props.data.totalPay}~COP~AMEX,BANK_REFERENCED,CODENSA,DINERS,MASTERCARD,VISA`)}
                            ></Payu>
                        </>
                    ) : (
                        <>
                            <Avvillas
                                referencia={props.data.referencia}
                                observacion={props.data.observacion}
                                total={props.data.totalPay}
                                usuario={props.data.usuario}
                                transaccionConvenioId={props.data.transaccionConvenioId}
                            ></Avvillas>
                        </>
                    )}

                </>
            ) : null}
        </React.Fragment>
    )
}

export default withRouter(connect(null, { ActualizarCarrito })(ThankYouPage))
